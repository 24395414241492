import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { withRouter } from 'react-router-dom';

import { DispatchFromProps, StateFromProps } from './types';
import { State } from '../../store/rootReducer';
import { load as loadCart } from '../../store/cart';
import { getIsStudent } from '../../store/checkout';
import App from './component';

const mapStateToProps = (state: State): StateFromProps => ({
  student: getIsStudent(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<State, any, AnyAction>): DispatchFromProps => ({
  loadCart: () => dispatch(loadCart()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
