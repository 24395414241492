import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet-async';

import { dateDiff } from '../../helpers/dateDiff';
import { formatDate } from '../../helpers/formatDate';
import { formatPrice } from '../../helpers/formatPrice';
import { isBrowser } from '../../helpers/utils';
import { Props, State } from './types';
import styles from './styles.module.scss';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import CheckoutForm from '../../components/CheckoutForm';
import Dialog from '../../components/Dialog';
import ProductItem from '../../components/ProductItem';
import ScrollTop from '../../components/ScrollTop';
import Spinner from '../../components/Spinner';
import Table, { TableBody, TableCell, TableHead, TableRow } from '../../components/Table';
import Terms from '../../components/Terms';
import Wrapper from '../../components/Wrapper';

const initialState: State = {
  isLoading: false,
  isTermsOpen: false,
  items: [],
  showError: false,
  showMap: false,
};

export default class CheckoutPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = initialState;
  }

  public onAcceptClick = () => {
    this.setState({ isTermsOpen: false });
    this.props.update('terms', true);
  };

  public onTermsClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    this.props.update('terms', checked);
    this.props.setValid(this.props.isValid && checked);
  };

  public onSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const { cart, checkout, isValid, submit } = this.props;

    if (isValid && checkout.terms && cart.items.length > 0) {
      this.setState({ isLoading: true });

      submit();

      if (checkout.message && (/tolva/i.test(checkout.message) || /12:a/i.test(checkout.message))) {
        console.log('easter egg');
      }
    }
  };

  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (!prevProps.submitSuccess && this.props.submitSuccess) {
      this.props.clear();
      this.props.history.push('/tack');
      return;
    }

    if (!prevProps.submitFail && this.props.submitFail) {
      this.setState({
        isLoading: false,
        showError: true,
      });
      return;
    }

    if (this.props.cart.items !== prevState.items) {
      this.setState({ items: this.props.cart.items });
      return;
    }
  }

  public componentDidMount() {
    if (isBrowser) {
      this.setState({ items: this.props.cart.items });
    }
  }

  public render() {
    const { cart, clear, checkout, isValid, preload, setValid, setQty, update } = this.props;
    const { isLoading, isTermsOpen, items, showError, showMap } = this.state;

    const startDate = formatDate(checkout.startDate, 'eeee d MMM') + (checkout.startDate ? ' 12:00' : '');

    const endDate = formatDate(checkout.endDate, 'eeee d MMM') + (checkout.endDate ? ' 12:00' : '');

    const numDays = checkout.startDate && checkout.endDate ? dateDiff(checkout.startDate, checkout.endDate) : 1;

    return (
      <>
        <Wrapper className={styles.block}>
          <h1>Bokningsförfrågan</h1>

          <div className={classNames(styles.grid, 'row--md')}>
            <CheckoutForm className={styles.main} data={checkout} onChange={update} onValidChange={setValid} />

            {items.length === 0 && (
              <div className={styles.empty}>
                <b>Din varukorg är tom</b>
              </div>
            )}

            {items.length > 0 && (
              <ul className={styles.list}>
                {items.map((item) => (
                  <li className={styles.item} key={item.product.name}>
                    <ProductItem item={item} preload={preload} setQty={setQty} />
                  </li>
                ))}
              </ul>
            )}

            <div className={styles.summary}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sammanfattning</TableCell>

                    <TableCell />
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell>Fraktalternativ</TableCell>

                    <TableCell modifiers="right">
                      <button
                        className={styles.map_button}
                        title="Visa karta"
                        onClick={() => this.setState({ showMap: true })}
                      >
                        Hämtas på plats
                      </button>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Hämtas</TableCell>

                    <TableCell modifiers="right">{startDate}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Lämnas</TableCell>

                    <TableCell modifiers="right">{endDate}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Antal dygn</TableCell>

                    <TableCell modifiers="right">{numDays}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Dygnshyra</TableCell>

                    <TableCell modifiers="right">
                      <span suppressHydrationWarning={true}>{formatPrice(cart.totalPrice)}</span>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <b>Totalt</b>
                    </TableCell>

                    <TableCell modifiers="right">
                      <b suppressHydrationWarning={true}>{formatPrice(cart.totalPrice * numDays)}</b>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              <Checkbox
                className="row--xl"
                checked={checkout.terms}
                name="terms"
                onChange={this.onTermsClick}
                type="checkbox"
              >
                {'Jag har läst och godkänt '}
                <Button modifiers="link" onClick={() => this.setState({ isTermsOpen: true })}>
                  uthyrningsvillkoren
                </Button>
                .
              </Checkbox>

              <Button
                className="row--xl"
                disabled={!(isValid && checkout.terms && cart.items.length > 0)}
                modifiers="primary large block"
                onClick={this.onSubmit}
              >
                {isLoading ? <Spinner /> : 'Skicka bokningsförfrågan'}
              </Button>
            </div>
          </div>
        </Wrapper>

        <Dialog
          animationDuration={150}
          closeOnBackdropClick={true}
          dimmable={true}
          isOpen={showMap}
          onClose={() => this.setState({ showMap: false })}
          showCloseButton={true}
          title="Hämtas här"
        >
          <iframe
            className={styles.map}
            // eslint-disable-next-line max-len
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2132.620032748245!2d11.971727115523727!3d57.688935148482756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464ff30c91c83531%3A0x69478b61e214d2f!2sSven+Hultins+gata+2A%2C+412+58+G%C3%B6teborg!5e0!3m2!1ssv!2sse!4v1444594269896"
          />
        </Dialog>

        <Dialog
          animationDuration={150}
          buttons={[
            <Button key={0} modifiers="secondary small" onClick={() => this.setState({ isTermsOpen: false })}>
              Avbryt
            </Button>,
            <Button key={1} modifiers="primary small" onClick={this.onAcceptClick}>
              Acceptera
            </Button>,
          ]}
          closeOnBackdropClick={false}
          dimmable={true}
          isOpen={isTermsOpen}
          modifiers="light"
          onClose={() => this.setState({ isTermsOpen: false })}
          showCloseButton={true}
          title="Uthyrningsvillkor"
        >
          <Terms className={styles.terms} />
        </Dialog>

        <Dialog
          animationDuration={150}
          className={styles.dialog}
          closeOnBackdropClick={true}
          dimmable={true}
          isOpen={showError}
          modifiers="light"
          onClose={() => this.setState({ showError: false })}
          showCloseButton={true}
          title="Fel"
        >
          Kunde inte skicka bokning. Försök igen senare.
        </Dialog>

        <ScrollTop />

        <Helmet title="Bokningsförfrågan" />
      </>
    );
  }
}
