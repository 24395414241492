import { Dispatch, AnyAction } from 'redux';

import { Cart } from '../../models/Cart';
import { Product } from '../../models/Product';
import { isBrowser } from '../../helpers/utils';
import { productPrice } from '../../helpers/productPrice';
import { ActionTypes } from './types';
import { getIsStudent } from '../checkout';
import { State } from '../rootReducer';

const calculateTotalPrice = (cart: Cart, student: boolean): number => {
  let totalPrice = 0;
  cart.items.forEach((item) => {
    totalPrice += productPrice(item.product, item.qty, student);
  });

  return totalPrice;
};

export const load = () => (dispatch: Dispatch<AnyAction>, getState: () => State) => {
  if (isBrowser) {
    const student = getIsStudent(getState());
    const str = window.localStorage.getItem('cart');
    const cart = str
      ? JSON.parse(str)
      : ({
          items: [],
          totalPrice: 0,
        } as unknown as Cart);

    const totalPrice = calculateTotalPrice(cart, student);
    cart.totalPrice = totalPrice;

    dispatch({
      type: ActionTypes.LOADING,
    });

    dispatch({
      type: ActionTypes.LOADED,
      payload: cart as unknown as Cart,
    });
  }
};

export const clear = () => (dispatch: Dispatch<AnyAction>) => {
  if (isBrowser) {
    const cart: Cart = {
      items: [],
      totalPrice: 0,
    };
    window.localStorage.setItem('cart', JSON.stringify(cart));

    dispatch({
      type: ActionTypes.CLEAR,
    });
  }
};

export const setQty = (product: Product, qty: number) => (dispatch: Dispatch<AnyAction>, getState: () => State) => {
  if (isBrowser) {
    const student = getIsStudent(getState());
    const str = window.localStorage.getItem('cart');
    const cart = str
      ? (JSON.parse(str) as Cart)
      : ({
          items: [],
          totalPrice: 0,
        } as unknown as Cart);

    const cartItem = cart.items.find((item) => item.product.name === product.name);
    if (cartItem) {
      cartItem.qty += qty;

      if (cartItem.qty === 0) {
        cart.items = cart.items.filter((item) => item !== cartItem);
      }
    } else {
      cart.items.push({
        product,
        qty,
      });
    }
    const totalPrice = calculateTotalPrice(cart, student);
    cart.totalPrice = totalPrice;

    window.localStorage.setItem('cart', JSON.stringify(cart));

    dispatch({
      type: ActionTypes.SET_QTY,
      payload: cart,
    });
  }
};
