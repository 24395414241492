import { useEffect, useState } from 'react';

import { FieldError } from '../models/FieldError';

export const useFieldErrors = (value: any, validators?: Array<(value: any) => FieldError | null>) => {
  const [errors, setErrors] = useState<FieldError[]>([]);

  useEffect(() => {
    const fieldErrors: FieldError[] = [];

    if (validators) {
      for (const validator of validators) {
        const fieldError = validator(value);

        if (fieldError) {
          fieldErrors.push(fieldError);
        }
      }
    }

    setErrors(fieldErrors);
  }, [value]);

  return errors;
};
