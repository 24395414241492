// Since there is an issue with locales and Node, do it yourself for Swedish numbers
// e.g 1234 becomes 1 234
const addThousandSeparator = (basePrice: string): string => {
  return basePrice
    .split('')
    .reverse()
    .map((char, i) => ((i + 1) % 4 === 0 ? char + ' ' : char))
    .reverse()
    .join('');
};

export const formatPrice = (price: number | undefined | null): string => {
  if (price === null || price === undefined || typeof price !== 'number' || isNaN(+price)) {
    return '';
  }

  const priceArray = price.toFixed(2).split('.');
  const basePrice = addThousandSeparator(priceArray[0]);
  const decimalPrice = priceArray[1];
  const priceString = decimalPrice === '00' ? basePrice : basePrice + ',' + decimalPrice;

  return priceString + ' kr';
};
