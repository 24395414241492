import { Product } from '../models/Product';

export const productPrice = (product: Product, qty: number, student: boolean): number => {
  if (product.xForPrice) {
    let price = 0;
    let rest = qty;

    while (rest >= product.xForPrice.x) {
      const campaignPrice = student ? product.xForPrice.prices.student : product.xForPrice.prices.normal;

      price += campaignPrice;
      rest -= product.xForPrice.x;
    }

    const ordinaryPrice = student ? product.prices.student : product.prices.normal;

    return rest * ordinaryPrice + price;
  } else {
    const price = student ? product.prices.student : product.prices.normal;
    return price * qty;
  }
};
