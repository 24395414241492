import { Action as HTTPAction } from '../types';
import { Category } from '../../models/Category';

export enum ActionTypes {
  PENDING = 'category/PENDING',
  LOADING = 'category/LOADING',
  LOADED = 'category/LOADED',
}

export interface CategoryState {
  data: Category;
  error: boolean;
  state: ActionTypes;
}

interface PendingAction {
  type: ActionTypes.PENDING;
}

interface LoadingAction {
  type: ActionTypes.LOADING;
}

interface LoadedAction extends HTTPAction {
  type: ActionTypes.LOADED;
  payload: Category;
}

export type Action = PendingAction | LoadingAction | LoadedAction;
