import React from 'react';
import { Helmet } from 'react-helmet-async';

import styles from './styles.module.scss';
import ScrollTop from '../../components/ScrollTop';
import Terms from '../../components/Terms';
import Wrapper from '../../components/Wrapper';

const TermsPage: React.FC = () => {
  return (
    <Wrapper className={styles.block}>
      <section className="row--md">
        <Terms />
      </section>

      <ScrollTop />

      <Helmet title="Villkor" />
    </Wrapper>
  );
};

export default TermsPage;
