import React from 'react';
import classNames from 'classnames';

import { Props } from './types';
import styles from '../styles.module.scss';

const TableCell: React.FC<Props> = (props) => {
  const { children, className, modifiers } = props;
  return (
    <td className={classNames(styles.cell, className)} scope="col">
      <div
        className={classNames(styles.cell_inner, modifiers ? modifiers.split(' ').map((m: string) => styles[m]) : null)}
      >
        {children}
      </div>
    </td>
  );
};

export default TableCell;
