import React from 'react';
import classNames from 'classnames';

import { Props } from './types';
import styles from '../styles.module.scss';

const TableRow: React.FC<Props> = (props) => {
  const { children, className } = props;
  return <tr className={classNames(styles.row, className)}>{children}</tr>;
};

export default TableRow;
