import React, { useState, useEffect, useRef } from 'react';

import { Props, Offset } from './types';
import { throttle } from '../../helpers/utils';

const DEFAULT_OFFSET = 100;
const THROTTLE_TRESHOLD = 250;

const getElementPosition = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect();

  return {
    top: rect.top + window.pageYOffset,
    left: rect.left + window.pageXOffset,
  };
};

const inViewport = (element: HTMLElement, container: HTMLElement | Window, offset: Offset): boolean => {
  if (element && element.offsetParent === null) {
    return false;
  }

  let top: number;
  let bottom: number;
  let left: number;
  let right: number;

  if (!container || container === window) {
    top = window.pageYOffset;
    left = window.pageXOffset;
    bottom = top + window.innerHeight;
    right = left + window.innerWidth;
  } else {
    const elem = container as HTMLElement;
    const containerPosition = getElementPosition(elem);

    top = containerPosition.top;
    left = containerPosition.left;
    bottom = top + elem.offsetHeight;
    right = left + elem.offsetWidth;
  }

  const elementPosition = getElementPosition(element);

  return (
    top <= elementPosition.top + element.offsetHeight + offset.top &&
    bottom >= elementPosition.top - offset.bottom &&
    left <= elementPosition.left + element.offsetWidth + offset.left &&
    right >= elementPosition.left - offset.right
  );
};

const style = (element: HTMLElement, prop: string): string =>
  typeof getComputedStyle !== 'undefined'
    ? getComputedStyle(element, null).getPropertyValue(prop)
    : element.style[prop];

const overflow = (element: HTMLElement): string =>
  style(element, 'overflow') + style(element, 'overflow-y') + style(element, 'overflow-x');

const getScrollParent = (element: HTMLElement | null) => {
  if (!(element instanceof HTMLElement)) {
    return window;
  }

  let parent = element;

  while (parent) {
    if (parent === document.body || parent === document.documentElement) {
      break;
    }

    if (!parent.parentNode) {
      break;
    }

    if (/(scroll|auto)/.test(overflow(parent))) {
      return parent;
    }

    parent = parent.parentNode as HTMLElement;
  }

  return window;
};

const LazyLoad: React.FC<Props> = (props) => {
  const { children, dimension, offset, ...rest } = props;
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  let scrollNode: HTMLElement | Window;

  const isNodeVisible = (): boolean => {
    const customOffset: Offset = offset || {
      top: DEFAULT_OFFSET,
      right: DEFAULT_OFFSET,
      bottom: DEFAULT_OFFSET,
      left: DEFAULT_OFFSET,
    };

    if (ref && ref.current) {
      return inViewport(ref.current, scrollNode, customOffset);
    }

    return false;
  };

  const onViewEvent = throttle(() => {
    if (isNodeVisible()) {
      setIsVisible(true);
      scrollNode.removeEventListener('scroll', onViewEvent);
    }
  }, THROTTLE_TRESHOLD);

  useEffect(() => {
    onViewEvent();
  }, [dimension.width]);

  useEffect(() => {
    scrollNode = getScrollParent(ref.current);

    if (isNodeVisible()) {
      setIsVisible(true);
    } else {
      // TODO add browser support for passive property. Modernizr
      scrollNode.addEventListener('scroll', onViewEvent, { passive: true });
    }

    return () => {
      scrollNode.removeEventListener('scroll', onViewEvent);
    };
  }, [children]);

  return (
    <div {...rest} ref={ref}>
      {isVisible ? children : null}
    </div>
  );
};

export default LazyLoad;
