import { CartState, Action, ActionTypes } from './types';

export const initialState: CartState = {
  data: {
    items: [],
    totalPrice: 0,
  },
  state: ActionTypes.PENDING,
};

export const reducer = (state: CartState = initialState, action: Action): CartState => {
  switch (action.type) {
    case ActionTypes.PENDING:
    case ActionTypes.LOADING:
      return {
        ...state,
        state: action.type,
      };
    case ActionTypes.LOADED:
    case ActionTypes.SET_QTY:
      return {
        data: action.payload,
        state: action.type,
      };
    case ActionTypes.CLEAR:
      return {
        data: initialState.data,
        state: action.type,
      };
    default:
      return state;
  }
};
