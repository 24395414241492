import React from 'react';
import { Link } from 'react-router-dom';

import { Props } from './types';
import styles from './styles.module.scss';
import Button from '../../components/Button';
import LazyLoad from '../../components/LazyLoad';
import LazyImage from '../../components/LazyImage';
import ScrollTop from '../../components/ScrollTop';
import Wrapper from '../../components/Wrapper';

const sections = [
  {
    title: 'Ljud',
    link: '/hyra/ljud',
    alt: 'Hyr ljud',
    img: '/images/item_001_big.jpg',
    text: `Vi har högkvalitativa ljudsystem till
    uthyrning med högtalare från amerikanska JBL
    som drivs av svenskutvecklade Lab.gruppen-slutsteg
    med effekter mellan 2400W upp till 19kW.`,
  },
  {
    title: 'Ljus',
    link: '/hyra/ljus',
    alt: 'Hyr ljus',
    img: '/images/item_037_big.jpg',
    // eslint-disable-next-line max-len
    text: 'Vare sig du ska ha mysbelysning eller tokfest med stroboskop och rök har vi det. Kolla in vårt utbud av ljus!',
  },
  {
    title: 'Bild',
    link: '/hyra/bild',
    alt: 'Hyr bild',
    img: '/images/item_020_big.jpg',
    // eslint-disable-next-line max-len
    text: 'Vi har mindre projektorer till film och presentation samt projektordukar till det. När det gäller kablar och adaptrar har vi det mesta!',
  },
];

export default class FrontPage extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    return (
      <>
        <div className={styles.start}>
          <div className={styles.bg_image} />

          <div className={styles.backdrop} />

          <div className={styles.overlay}>
            <div className={styles.inner}>
              <h1>Söker du fett ljud och ljus?</h1>

              <p>
                Ljud- och Bildgruppen Chalmers har det mesta vare sig du ska ha en liten fest för 20 personer eller
                konsert för 1000 personer.
              </p>

              <Button className={styles.button} modifiers="primary small rounded" to="/hyra/ljud">
                Hyr
              </Button>
            </div>
          </div>
        </div>

        <Wrapper as="section" className={styles.section}>
          <h2>Bli festens hjälte</h2>

          <p className={styles.text}>
            Ljud- och Bildgruppen, LoB, är en kommitté inom{' '}
            <a target="_blank" rel="noreferrer noopener" href="http://chalmersstudentkar.se">
              Chalmers Studentkår
            </a>{' '}
            som ideellt hyr ut ljud- och ljusanläggningar, riggar och planerar ljud och ljus till kalas, dansgolv och
            konserter. Vi har det mesta vare sig du ska ha en liten fest för 20 personer eller konsert för 1000
            personer. Vi har även allt du behöver för filmkvällen eller presentationen.
          </p>
        </Wrapper>

        <section className={styles.categories}>
          <Wrapper>
            {sections.map((section, i) => (
              <Link className={styles.category_row} key={i} title={section.alt} to={section.link}>
                <div className={styles.category_col}>
                  <LazyLoad className={styles.image_container}>
                    <LazyImage alt={section.alt} className={styles.category_image} src={section.img} />
                  </LazyLoad>
                </div>

                <LazyLoad className={styles.category_col}>
                  <div className={styles.up}>
                    <h2 className="h1">{section.title}</h2>

                    <p>{section.text}</p>
                  </div>
                </LazyLoad>
              </Link>
            ))}
          </Wrapper>
        </section>

        <ScrollTop />
      </>
    );
  }
}
