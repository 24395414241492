import React, { useState, useRef, memo } from 'react';

import { Props } from './types';
import styles from './styles.module.scss';
import { formatDate } from '../../helpers/formatDate';
import { ValidationError } from '../../models/ValidationError';
import InputError from '../InputError';
import Calendar from '../Calendar';
import Dropdown from '../Dropdown';
import Menu from '../Menu';

const Datepicker: React.FC<Props> = (props) => {
  const { className, disabled, label, maxDate, minDate, onChange, placeholder, required, value } = props;
  const element = useRef<HTMLDivElement | null>(null);
  const [isDirty, setIsDirty] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const invalid = isDirty && !isOpen;

  const onBlur = () => setIsDirty(true);

  const onCalendarChange = (date: Date) => {
    onChange(date);
    setIsOpen(false);
  };

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className={className}>
      <div className={styles.container} ref={element}>
        <Dropdown
          disabled={disabled}
          isOpen={isOpen}
          label={label}
          onBlur={onBlur}
          onClick={toggle}
          placeholder={placeholder}
          required={required}
          value={formatDate(value, 'eeee d MMM') + (value ? ' 12:00' : '')}
        >
          <Menu
            animationDuration={150}
            as="div"
            closeOnBackdropClick={true}
            container={element.current}
            dimmable={false}
            isOpen={isOpen}
            onClose={toggle}
          >
            <Calendar maxDate={maxDate} minDate={minDate} onChange={onCalendarChange} value={value} />
          </Menu>
        </Dropdown>
      </div>

      <InputError
        errors={
          required && !value
            ? [
                {
                  type: ValidationError.REQUIRED,
                  text: 'Vänligen välj ett datum',
                },
              ]
            : []
        }
        show={invalid}
      />
    </div>
  );
};

export default memo(Datepicker);
