import React, { useState, useEffect } from 'react';
import { addDays } from 'date-fns';

import { Checkout } from '../../models/Checkout';
import { InputField } from '../../models/InputField';
import { Validators } from '../../models/Validators';
import { useFormValidation } from '../../hooks/formValidation';
import { Props } from './types';
import styles from './styles.module.scss';
import Checkbox from '../Checkbox';
import Datepicker from '../Datepicker';
import Input from '../Input';

const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

const CheckoutForm: React.FC<Props> = (props) => {
  const { className, data, onChange, onValidChange } = props;
  const [fields, setFields] = useState<{ [P in keyof Partial<Checkout>]: InputField }>({
    student: {
      value: false,
      validators: [],
    },
    firstName: {
      value: '',
      validators: [Validators.required()],
    },
    lastName: {
      value: '',
      validators: [Validators.required()],
    },
    email: {
      value: '',
      validators: [Validators.required(), Validators.email()],
    },
    telephone: {
      value: '',
      validators: [Validators.required(), Validators.telephone()],
    },
    startDate: {
      value: undefined,
      validators: [Validators.required()],
    },
    endDate: {
      value: undefined,
      validators: [Validators.required()],
    },
    message: {
      value: '',
      validators: [],
    },
  });
  const isValid = useFormValidation(fields);

  const onInputChange = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    onChange(name, value);
  };

  const onCheckboxChange = (event: React.ChangeEvent<any>) => {
    const { name, checked } = event.target;
    onChange(name, checked);
  };

  useEffect(() => {
    setFields({
      ...fields,
      student: {
        ...fields.student!,
        value: data.student,
      },
      firstName: {
        ...fields.firstName!,
        value: data.firstName,
      },
      lastName: {
        ...fields.lastName!,
        value: data.lastName,
      },
      email: {
        ...fields.email!,
        value: data.email,
      },
      telephone: {
        ...fields.telephone!,
        value: data.telephone,
      },
      startDate: {
        ...fields.startDate!,
        value: data.startDate,
      },
      endDate: {
        ...fields.endDate!,
        value: data.endDate,
      },
      message: {
        ...fields.message!,
        value: data.message,
      },
    });
  }, [data]);

  useEffect(() => {
    onValidChange(isValid);
  }, [fields, isValid]);

  return (
    <div className={className}>
      <div className={styles.row}>
        <div className={styles.col}>
          <Input
            className="row--xs"
            label="Förnamn"
            name="firstName"
            onChange={onInputChange}
            type="text"
            validators={fields.firstName!.validators}
            value={fields.firstName!.value}
          />
        </div>

        <div className={styles.col}>
          <Input
            className="row--xs"
            label="Efternamn"
            name="lastName"
            onChange={onInputChange}
            type="text"
            validators={fields.lastName!.validators}
            value={fields.lastName!.value}
          />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.col}>
          <Input
            className="row--xs"
            label="E-post"
            name="email"
            onChange={onInputChange}
            type="email"
            validators={fields.email!.validators}
            value={fields.email!.value}
          />
        </div>

        <div className={styles.col}>
          <Input
            className="row--xs"
            label="Telefonnummer"
            name="telephone"
            onChange={onInputChange}
            type="tel"
            validators={fields.telephone!.validators}
            value={fields.telephone!.value}
          />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.col}>
          <Datepicker
            className="row--xs"
            label="Avhämtning"
            minDate={today}
            maxDate={fields.endDate!.value}
            onChange={(date) => onChange('startDate', date)}
            placeholder="Datum"
            required={true}
            value={fields.startDate!.value}
          />
        </div>

        <div className={styles.col}>
          <Datepicker
            className="row--xs"
            label="Avlämning"
            minDate={addDays(fields.startDate!.value || today, 1)}
            onChange={(date) => onChange('endDate', date)}
            placeholder="Datum"
            required={true}
            value={fields.endDate!.value}
          />
        </div>
      </div>

      <Checkbox
        className="row--xs"
        checked={fields.student!.value}
        name="student"
        onChange={onCheckboxChange}
        type="checkbox"
      >
        Kårmedlem (du måste uppvisa giltigt kårkort)
      </Checkbox>

      <Input
        autoComplete="off"
        className={styles.message}
        element="textarea"
        label="Meddelande"
        name="message"
        onChange={onInputChange}
        type="text"
        placeholder="Skriv gärna om du hyr för en kommité på chalmers"
        validators={fields.message!.validators}
        value={fields.message!.value}
      />
    </div>
  );
};

export default CheckoutForm;
