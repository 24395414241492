import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';

import { Props } from './types';
import styles from './styles.module.scss';

const LazyImage: React.FC<Props> = (props) => {
  const { src, srcSet } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const image = useRef<HTMLImageElement>(null);

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(false);

      if (image && image.current) {
        if (!image.current.complete) {
          image.current.onload = () => {
            setIsLoaded(true);
          };
        } else {
          setIsLoaded(true);
        }
      }
    }, 0);
  }, [src, srcSet]);

  return (
    <img {...props} className={classNames(props.className, styles.image, { [styles.loaded]: isLoaded })} ref={image} />
  );
};

export default LazyImage;
