import React from 'react';
import classNames from 'classnames';

import { Props } from './types';
import styles from './styles.module.scss';

const Spinner: React.FC<Props> = (props) => {
  const { className } = props;
  return <div className={classNames(styles.spinner, className)} />;
};

export default Spinner;
