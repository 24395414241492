export enum ActionTypes {
  SET = 'dimension/SET',
}

export interface DimensionState {
  width: number;
  height: number;
}

interface ResizeDimensionAction {
  type: ActionTypes.SET;
  width: number;
  height: number;
}

export type DimensionAction = ResizeDimensionAction;
