import { FieldError } from './FieldError';
import { ValidationError } from './ValidationError';

const EMAIL_PATTERN =
  // eslint-disable-next-line max-len
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
const TELEPHONE_PATTERN = /^[0-9+\- ]+$/;

export class Validators {
  public static email(message?: string) {
    return (value: string): FieldError | null =>
      EMAIL_PATTERN.test(value)
        ? null
        : {
            type: ValidationError.EMAIL,
            text: message || 'Vänligen ange en giltig e-postadress',
          };
  }

  public static telephone(message?: string) {
    return (value: string): FieldError | null =>
      TELEPHONE_PATTERN.test(value)
        ? null
        : {
            type: ValidationError.TELEPHONE,
            text: message || 'Vänligen ange ett giltigt telefonnummer',
          };
  }

  public static maxLength(length: number, message?: string) {
    return (value: string): FieldError | null =>
      typeof length === 'number' && length >= 0 && value.length <= length
        ? null
        : {
            type: ValidationError.MAX_LENGTH,
            text: message || `Vänligen ange max ${length} tecken`,
          };
  }

  public static minLength(length: number, message?: string) {
    return (value: string): FieldError | null =>
      typeof length === 'number' && length >= 0 && value.length >= length
        ? null
        : {
            type: ValidationError.MIN_LENGTH,
            text: message || `Vänligen ange minst ${length} tecken`,
          };
  }

  public static num(message?: string) {
    return (value: string): FieldError | null =>
      !isNaN(+value)
        ? null
        : {
            type: ValidationError.NUMBER,
            text: message || 'Vänligen ange endast numeriska värden',
          };
  }

  public static pattern(pattern: RegExp, message?: string) {
    return (value: string): FieldError | null =>
      pattern instanceof RegExp && pattern.test(value)
        ? null
        : {
            type: ValidationError.PATTERN,
            text: message || 'Ogiltigt format',
          };
  }

  public static required(message?: string) {
    return (value: string | boolean): FieldError | null =>
      value
        ? null
        : {
            type: ValidationError.REQUIRED,
            text: message || 'Detta fält är obligatoriskt',
          };
  }
}
