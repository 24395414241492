import { Dispatch, AnyAction } from 'redux';

import { http } from '../../helpers/utils';
import { ActionTypes } from './types';

export const load = () => (dispatch: Dispatch<AnyAction>) => {
  dispatch({
    type: ActionTypes.LOADING,
  });

  dispatch({
    type: ActionTypes.LOADED,
    payload: http('/api/v1/lobare').then((res: Response) => res.json()),
  });
};
