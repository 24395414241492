import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { DispatchFromProps, StateFromProps } from './types';
import { State } from '../../store/rootReducer';
import { getIsPending, getLobare, load } from '../../store/lobare';
import AboutPage from './component';

const mapStateToProps = (state: State): StateFromProps => ({
  isPending: getIsPending(state),
  lobare: getLobare(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<State, any, AnyAction>): DispatchFromProps => ({
  load: () => dispatch(load()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AboutPage);
