import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { StateFromProps } from './types';
import { State } from '../../store/rootReducer';
import { getRouteHistoryState } from '../../store/routeHistory';
import Breadcrumbs from './component';

const mapStateToProps = (state: State): StateFromProps => ({
  previousLocation: getRouteHistoryState(state).previousLocation,
});

export default withRouter(
  connect(
    mapStateToProps,

    // Set dispatch props to empty object to enable props spreading
    () => ({}),
  )(Breadcrumbs),
);
