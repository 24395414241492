import React, { useState } from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet-async';

import { Props } from './types';
import styles from './styles.module.scss';
import { http } from '../../helpers/utils';
import { useFieldState } from '../../hooks/fieldState';
import { useFormValidation } from '../../hooks/formValidation';
import { Contact } from '../../models/Contact';
import { Validators } from '../../models/Validators';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import Dialog from '../../components/Dialog';
import Input from '../../components/Input';
import ScrollTop from '../../components/ScrollTop';
import Wrapper from '../../components/Wrapper';

const MAIL = 'info@ljudobildgruppen.se';

const ContactPage: React.FC<Props> = (props) => {
  const [fail, setIsFail] = useState(false);
  const [success, setIsSuccess] = useState(false);
  const [fields, setFields] = useFieldState({
    name: {
      value: '',
      validators: [Validators.required()],
    },
    email: {
      value: '',
      validators: [Validators.required(), Validators.email()],
    },
    telephone: {
      value: '',
      validators: [Validators.required(), Validators.telephone()],
    },
    message: {
      value: '',
      validators: [],
    },
    student: {
      value: false,
      validators: [],
    },
  });
  const isValid = useFormValidation(fields);

  const onCheckboxChange = (event: React.ChangeEvent<any>) => {
    const { name, checked } = event.target;
    setFields(name, checked);
  };

  const onInputChange = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    setFields(name, value);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const contact: Contact = {
      name: fields.name.value,
      email: fields.email.value,
      telephone: fields.telephone.value,
      message: fields.message.value,
      student: fields.student.value,
    };

    if (isValid) {
      http('/api/v1/contact', {
        method: 'POST',
        body: JSON.stringify({ contact }),
      })
        .then(() => setIsSuccess(true))
        .catch(() => setIsFail(true));
    }
  };

  const onSuccessClose = () => {
    setIsSuccess(false);
    props.history.push('/');
  };

  return (
    <Wrapper>
      <h1>Kontakt</h1>

      <section className={styles.row}>
        <div className={styles.col}>
          <p className="row--md">
            Vänligen hör av dig om du önskar hyra eller har andra frågor. Vi har öppet mellan klockan 12:00 till 12:30,
            måndag-lördag. Eller maila oss på{' '}
            <a className="link" href={`mailto:${MAIL}`}>
              {MAIL}
            </a>
            .
          </p>

          <p className="row--md">
            Ljud- och Bildgruppen Chalmers Studentkår
            <br />
            Sven Hultins gata 2A
            <br />
            412 58 GÖTEBORG
          </p>

          <p className="row--md">
            Telefon
            <br />
            <a className="link" href="tel:0317723922">
              031 - 772 39 22
            </a>
          </p>

          <div className="row--lg">
            <iframe
              className={styles.map}
              // eslint-disable-next-line max-len
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2132.620032748245!2d11.971727115523727!3d57.688935148482756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464ff30c91c83531%3A0x69478b61e214d2f!2sSven+Hultins+gata+2A%2C+412+58+G%C3%B6teborg!5e0!3m2!1ssv!2sse!4v1444594269896"
              title="Karta"
            />
          </div>
        </div>

        <form className={styles.col} onSubmit={onSubmit}>
          <Input
            className="row--xs"
            label="Namn"
            name="name"
            onChange={onInputChange}
            type="text"
            validators={fields.name.validators}
            value={fields.name.value}
          />

          <Input
            className="row--xs"
            label="E-post"
            name="email"
            onChange={onInputChange}
            type="email"
            validators={fields.email.validators}
            value={fields.email.value}
          />

          <Input
            className="row--xs"
            label="Telefonnummer"
            name="telephone"
            onChange={onInputChange}
            type="tel"
            validators={fields.telephone.validators}
            value={fields.telephone.value}
          />

          <Input
            className="row--xs"
            element="textarea"
            label="Meddelande"
            name="message"
            onChange={onInputChange}
            type="text"
            validators={fields.message.validators}
            value={fields.message.value}
          />

          <Checkbox
            className="row--xs"
            checked={fields.student.value}
            name="student"
            onChange={onCheckboxChange}
            type="checkbox"
          >
            Kårmedlem?
          </Checkbox>

          <Button
            className={classNames('row--md', styles.submit)}
            disabled={!isValid}
            modifiers="primary"
            title="Skicka"
            type="submit"
          >
            Skicka
          </Button>
        </form>
      </section>

      <Dialog
        animationDuration={150}
        className={styles.dialog}
        closeOnBackdropClick={true}
        dimmable={true}
        isOpen={success && !fail}
        modifiers="light"
        onClose={onSuccessClose}
        showCloseButton={true}
        title="Tack för ditt meddelande!"
      >
        Vi hör av oss så fort vi kan.
      </Dialog>

      <Dialog
        animationDuration={150}
        className={styles.dialog}
        closeOnBackdropClick={true}
        dimmable={true}
        isOpen={!success && fail}
        modifiers="light"
        onClose={() => setIsFail(false)}
        showCloseButton={true}
        title="Fel"
      >
        Kunde inte skicka formulär. Försök igen senare.
      </Dialog>

      <ScrollTop />

      <Helmet title="Kontakt" />
    </Wrapper>
  );
};

export default ContactPage;
