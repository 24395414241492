import React from 'react';
import classNames from 'classnames';

import { Props } from './types';
import styles from './styles.module.scss';

const Terms: React.FC<Props> = ({ className }) => (
  <>
    <h1 className="h3">Uthyrningsvillkor Ljud- och Bildgruppen</h1>

    <p>Gällande från 2020-07-01 tills vidare.</p>

    <ol className={classNames(styles.list, className)}>
      <li className={styles.item}>Avtalet tecknas mellan Ljud- och Bildgruppen (LoB) och hyrestagaren.</li>

      <li className={styles.item}>
        Hyresavtalet gäller både när LoB har personal på plats och när hyrestagaren själv tar hand om utrustningen. Om
        inget annat har avtalats så ingår inte personal i hyrespriset.
      </li>

      <li className={styles.item}>
        Hyrestagaren hämtar och lämnar själv utrustningen i LoBs lokaler i Chalmers kårhus om inget annat har avtalats.
        Eventuella transportkostnader debiteras hyrestagaren.
      </li>

      <li className={styles.item}>
        Utrustningen hämtas mellan 12.00 och 12.30 på hyresdagen och lämnas tillbaka 12.00 på återlämningsdagen.
        <br />
        <b>
          Det är mycket viktigt att utrustningen lämnas tillbaka 12.00 då en ny kund oftast står och väntar på
          utrustningen.
        </b>
      </li>

      <li className={styles.item}>
        Hyra betalas via kort när utrustningen hämtas om inget annat har avtalats. Förutom hyran ska en deposition på
        lika mycket som hyresbeloppet erläggas. Träffas överenskommelse om att hyresbeloppet ska faktureras så gäller
        betalningsvillkoret <b>30 dagar netto</b> om inget annat anges.
      </li>

      <li className={styles.item}>
        Återlämnas utrustningen för sent (efter <b>12.30</b> återlämningsdagen) debiteras 20% av hyran (dock minst
        100kr). Efter 13.00 utgår full dygnshyra för varje påbörjat dygn plus hyra för eventuell ersättningsutrustning.
      </li>

      <li className={styles.item}>
        Hyrestagaren är ensam ansvarig, under hela hyresperioden, för skada på/förlust av hyrd utrustning oavsett om det
        beror på vållande eller olyckshändelse. Vid eventuell skada på/förlust av hela eller delar av utrustningen, är
        hyrestagaren skyldig att utan dröjsmål ersätta LoB för den skadade/förlorade utrustningen med vad det kostar att
        återanskaffa eller reparera den försvunna/skadade utrustningen. Hyrestagaren är skyldig att meddela LoB när
        skada/förlust har inträffat. Med skada avses ej interna fel på utrustningen orsakade av förslitning eller
        fabrikationsfel, som hyrestagaren ej kan hållas ansvarig för.
      </li>

      <li className={styles.item}>
        LoB ansvarar för att utrustningen är felfri och funktionsduglig vid utlämningstillfället. Det åligger dock
        hyrestagaren att vid hyresperiodens början kontrollera att utrustningen är i funktionsdugligt skick. Skulle
        något vara felaktigt eller fel uppstå under hyresperioden ska LoB meddelas snarast. Reparation får endast ske av
        LoB eller av LoB utsedd person. Hyrestagaren är ansvarig för att defekt utrustning kommer LoB tillhanda för
        reparation. LoB har rätt att ersätta utrustningen med likvärdig utrustning om ersättningsutrustning av samma typ
        ej finns tillgänglig.
      </li>

      <li className={styles.item}>
        Hyrestagaren är skyldig att äga nödvändiga kunskaper för att använda utrustningen. Till exempel åligger det
        hyrestagaren att tillse att fullgott väderskydd finns då utrustningen används utomhus. Rådfråga gärna LoB vid
        oklarheter. Har fel uppstått p.g.a. åtgärd från hyrestagaren är LoB ej skyldiga att ställa annan felfri
        utrustning till förfogande eller reparera utrustningen utan att hyrestagaren erlägger ersättning härför.
      </li>

      <li className={styles.item}>
        LoB skall ha möjlighet att när som helst detta anses nödvändigt få tillträde till utrustningen för kontroll av
        funktion.
      </li>

      <li className={styles.item}>
        Utrustningen kvarstår i LoB:s ägo under hela hyresperioden. Hyrestagaren får därför ej låna ut, pantsätta,
        överlåta, byta ut eller sälja utrustningen. Utrustningen får ej heller uthyras i andra hand eller föras
        utomlands utan LoB:s skriftliga medgivande.
      </li>

      <li className={styles.item}>
        LoB kan ej göras ekonomiskt ersättningsskyldiga för misslyckad eller inställd föreställning, konsert, visning
        eller liknande som orsakats av fel på utrustningen, transportförseningar, force majeure eller andra
        omständigheter som LoB ej rår över. LoB ansvarar ej för skada som p.g.a. hyresförhållandet kan komma att drabba
        tredje man.
      </li>

      <li className={styles.item}>
        Utrustning och kablage ska vid återlämnandet vara packat och ihoprullat som vid utlämnandet. Smutsiga/kladdiga
        kablar och övrig utrustning ska rengöras före återlämning. Om packning och hoprullning av kablage måste göras om
        så debiteras hyrestagaren för detta. Om utrustning saknas vid återlämnandet debiteras hyrestagaren dess fulla
        värde.
      </li>

      <li className={styles.item}>
        LoB har rätt att med omedelbar verkan häva uthyrningsavtalet och återtaga utrustningen då hyrestagaren:
        <ol type="a">
          <li>försätts i konkurs, hamnar på obestånd eller avlider.</li>
          <li>vanvårdar, utlånar, pantsätter eller överlåter utrustningen.</li>
          <li>använder utrustningen under sådana förutsättningar som kan förorsaka skada.</li>
          <li>i övrigt åsidosätter innehållet i detta avtal.</li>
        </ol>
      </li>

      <li className={styles.item}>
        Om villkor i separat avtal eller tillägg avviker från detta hyresavtal ska sådant avtal gälla framför dessa
        uthyrningsvillkor. Tillägg eller ändringar ska vara skriftliga för att gälla.
      </li>
    </ol>
  </>
);

export default Terms;
