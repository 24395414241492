import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';

import { isBrowser } from '../../helpers/utils';
import { Props } from './types';
import inputStyles from '../Input/styles.module.scss';
import styles from './styles.module.scss';

import ChevronIcon from '!svg-react-loader?name=ChevronIcon!../../../assets/icons/chevron.svg';

const Dropdown: React.FC<Props> = (props) => {
  const { className, children, disabled, isOpen, label, onBlur, onClick, placeholder, required, value } = props;
  const elementRef = useRef<HTMLButtonElement | null>(null);

  const onKeyPress = (event: KeyboardEvent) => {
    if (event.key !== 'Escape' || event.defaultPrevented) {
      return;
    }

    onClick(event);
    event.preventDefault();
  };

  useEffect(() => {
    if (isBrowser && isOpen && elementRef.current) {
      elementRef.current.addEventListener('keyup', onKeyPress);
    }

    return () => {
      if (isBrowser && elementRef.current) {
        elementRef.current.removeEventListener('keyup', onKeyPress);
      }
    };
  }, [isOpen]);

  return (
    <div className={classNames(inputStyles.block, className, { [inputStyles.floating]: false })}>
      {label && (
        <label
          className={classNames(inputStyles.label, {
            [inputStyles.invalid]: !!required && !value,
            [inputStyles.floating]: false,
            [inputStyles.static]: true,
            [inputStyles.asterisk]: !!required,
          })}
        >
          {label}
        </label>
      )}
      <button
        className={classNames(inputStyles.input, styles.button)}
        disabled={disabled}
        onBlur={onBlur}
        onClick={onClick}
        ref={elementRef}
      >
        <span className={classNames({ [inputStyles.placeholder]: !value && !!placeholder })}>
          {value || placeholder}
        </span>

        <ChevronIcon
          className={classNames(styles.chevron, {
            [styles.is_open]: isOpen,
            [styles.faded]: disabled,
          })}
        />
      </button>

      {children}
    </div>
  );
};

export default Dropdown;
