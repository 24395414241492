import fetch from 'isomorphic-fetch';

let plugCookies: { [key: string]: string } = {};

export const isBrowser = !!(typeof window !== 'undefined' && window.document && window.document.createElement);

export const plugToRequest = (cookies: { [key: string]: string }) => {
  plugCookies = cookies;
};

export const unplugToRequest = () => {
  plugCookies = {};
};

export const getPlugCookies = (): { [key: string]: string } => {
  return plugCookies;
};

export const stringifyCookies = (cookies: { [key: string]: string }): string => {
  let cookieString = '';

  for (const key in cookies) {
    // eslint-disable-next-line no-prototype-builtins
    if (cookies.hasOwnProperty(key)) {
      cookieString += `${key}=${cookies[key]};`;
    }
  }

  return cookieString;
};

export const isPromise = (obj: any): boolean => {
  return !!obj && (typeof obj === 'object' || typeof obj === 'function') && typeof obj.then === 'function';
};

export const debounce = <T extends (...args: any[]) => void>(callback: T, delay: number) => {
  let timeout: any;
  return (...args: Parameters<T>) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      timeout = null;
      callback(...args);
    }, delay);
  };
};

export const throttle = <T extends (...args: any[]) => void>(callback: T, delay: number) => {
  let timeout: any;
  return (...args: Parameters<T>) => {
    if (!timeout) {
      timeout = setTimeout(() => {
        timeout = null;
        callback(...args);
      }, delay);
    }
  };
};

export const http = async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
  const headers = init && init.headers ? new Headers(init.headers) : new Headers();
  headers.append('Content-Type', 'application/json');

  if (typeof input === 'string' && input.indexOf('http') === -1) {
    input = isBrowser ? window.location.origin + input : `http://${process.env.HOST}:${process.env.PORT + input}`;
  }

  if (!isBrowser) {
    const cookies = getPlugCookies();
    headers.append('Cookie', stringifyCookies(cookies));
  }

  const _init: RequestInit = {
    ...init,
    credentials: 'include',
    headers,
  };

  return fetch(input, _init).then((res) => {
    if (res.status >= 400) {
      return Promise.reject(res);
    }

    return Promise.resolve(res);
  });
};
