import { useEffect, useState } from 'react';

export const useFadeOut = (show: boolean) => {
  const [isVisible, setIsVisible] = useState(false);
  const [shouldFadeOut, setShouldFadeOut] = useState(false);

  useEffect(() => {
    if (show) {
      setIsVisible(true);
    } else if (isVisible) {
      setShouldFadeOut(true);
    }
  }, [show]);

  return shouldFadeOut;
};
