import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { DispatchFromProps, StateFromProps } from './types';
import { State } from '../../store/rootReducer';
import { clear, setQty, getCart } from '../../store/cart';
import { preload } from '../../store/product';
import {
  getIsValid,
  update,
  setValid,
  submit,
  getData as getCheckoutData,
  getIsSubmitSuccess,
  getIsSubmitFail,
} from '../../store/checkout';
import CheckoutPage from './component';

const mapStateToProps = (state: State): StateFromProps => ({
  cart: getCart(state),
  checkout: getCheckoutData(state),
  isValid: getIsValid(state),
  submitSuccess: getIsSubmitSuccess(state),
  submitFail: getIsSubmitFail(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<State, any, AnyAction>): DispatchFromProps => ({
  clear: () => dispatch(clear()),
  preload: (product) => dispatch(preload(product)),
  setValid: (isValid) => dispatch(setValid(isValid)),
  setQty: (product, qty) => dispatch(setQty(product, qty)),
  submit: () => dispatch(submit()),
  update: (key, value) => dispatch(update(key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);
