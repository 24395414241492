import React from 'react';
import classNames from 'classnames';

import { Props } from './types';
import styles from './styles.module.scss';

const Table: React.FC<Props> = (props) => {
  const { children, className } = props;
  return <table className={classNames(styles.table, className)}>{children}</table>;
};

export default Table;
