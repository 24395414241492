import React from 'react';

import { ContainerProps } from './types';
import Menu from './Menu';
import MenuItem from './MenuItem';
import Modal from '../Modal';

const MenuContainer: React.FC<ContainerProps> = (props) => <Modal component={Menu} {...props} />;

export { MenuItem };
export default MenuContainer;
