import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';

import { Props } from './types';
import styles from './styles.module.scss';

export const Popup: React.FC<Props> = (props) => {
  const { animationDuration, children, modifiers, onClose, show } = props;
  const duration = (animationDuration || 0) + 'ms';
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (show) {
      if (timeoutRef.current !== undefined) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        onClose();
      }, 5000);
    }
  }, [show, onClose]);

  return (
    <div
      className={classNames(styles.block, modifiers ? modifiers.split(' ').map((m: string) => styles[m]) : null, {
        [styles.show]: show,
      })}
      role="dialog"
      style={{
        transitionDuration: duration,
      }}
      tabIndex={-1}
    >
      {children}
    </div>
  );
};
