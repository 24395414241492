import formatter from 'date-fns/format';
import { sv } from 'date-fns/locale';

export const formatDate = (
  date: number | string | Date | null | undefined,
  format: string,
  options?:
    | {
        locale?: Locale | undefined;
        weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined;
        firstWeekContainsDate?: number | undefined;
        useAdditionalWeekYearTokens?: boolean | undefined;
        useAdditionalDayOfYearTokens?: boolean | undefined;
      }
    | undefined,
): string => {
  if (!date) {
    return '';
  }

  const d = typeof date === 'string' ? new Date(date) : date;

  return formatter(d, format, {
    ...options,
    locale: sv,
  });
};
