import Table from './Table';
import TableBody from './TableBody';
import TableCell from './TableCell';
import TableHead from './TableHead';
import TableRow from './TableRow';

export { TableBody };
export { TableCell };
export { TableHead };
export { TableRow };
export default Table;
