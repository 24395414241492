import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { StateFromProps } from './types';
import { State } from '../../store/rootReducer';
import { getDimension } from '../../store/dimension';
import LazyLoad from './component';

const mapStateToProps = (state: State): StateFromProps => ({
  dimension: getDimension(state),
});

export default connect(
  mapStateToProps,

  // Set dispatch props to empty object to enable props spreading
  () => ({}),
)(LazyLoad);
