import { addDays } from 'date-fns';

import { Day } from '../components/Calendar/types';

export const dateDiff = (startDate: Date, endDate: Date): number => {
  let counter = 0;
  let currentDate = startDate;

  while (currentDate.getTime() < endDate.getTime()) {
    currentDate = addDays(currentDate, 1);
    if ((currentDate.getDay() + 6) % 7 !== Day.SUNDAY) {
      counter++;
    }
  }

  return counter;
};
