import { createSelector } from 'reselect';

import { State } from '../rootReducer';
import { CartState, ActionTypes } from './types';

export const getCartState = (state: State) => state.cart;

export const getIsPending = createSelector([getCartState], (s: CartState) => s.state === ActionTypes.PENDING);
export const getCart = createSelector([getCartState], (s: CartState) => s.data);

export const getTotalQuantity = createSelector([getCartState], (s: CartState) => {
  return s.data.items.map((item) => item.qty).reduce((prev, curr) => prev + curr, 0);
});
