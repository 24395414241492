import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { DispatchFromProps, StateFromProps } from './types';
import { State } from '../../store/rootReducer';
import { getIsPending, getProduct, load, getRelatedProducts, loadRelated, preload } from '../../store/product';
import { getIsStudent, update } from '../../store/checkout';
import { setQty } from '../../store/cart';
import ProductPage from './component';

const mapStateToProps = (state: State): StateFromProps => ({
  isPending: getIsPending(state),
  product: getProduct(state),
  relatedProducts: getRelatedProducts(state),
  student: getIsStudent(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<State, any, AnyAction>): DispatchFromProps => ({
  load: (name) => dispatch(load(name)),
  loadProducts: (name) => dispatch(loadRelated(name)),
  preload: (product) => dispatch(preload(product)),
  setQty: (product, qty) => dispatch(setQty(product, qty)),
  update: (key, value) => dispatch(update(key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage);
