import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';

import { Props } from './types';
import styles from './styles.module.scss';

const Nav: React.FC<Props> = (props) => {
  const { className, highlight, location } = props;

  const onClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <ul className={className}>
      <li>
        <Link
          className={classNames(styles.link, { [styles.active]: highlight && location.pathname.indexOf('/hyra') > -1 })}
          onClick={onClose}
          title="Hyra"
          to="/hyra/ljud"
        >
          Hyra
        </Link>
      </li>

      <li>
        <Link
          className={classNames(styles.link, { [styles.active]: highlight && location.pathname.indexOf('/aspa') > -1 })}
          onClick={onClose}
          title="Aspa"
          to="/aspa"
        >
          Aspa
        </Link>
      </li>

      <li>
        <Link
          className={classNames(styles.link, {
            [styles.active]: highlight && location.pathname.indexOf('/om-oss') > -1,
          })}
          onClick={onClose}
          title="Om oss"
          to="/om-oss"
        >
          Om oss
        </Link>
      </li>

      <li>
        <Link
          className={classNames(styles.link, {
            [styles.active]: highlight && location.pathname.indexOf('/kontakt') > -1,
          })}
          onClick={onClose}
          title="Kontakt"
          to="/kontakt"
        >
          Kontakt
        </Link>
      </li>

      <li>
        <Link
          className={classNames(styles.link, {
            [styles.active]: highlight && location.pathname.indexOf('/villkor') > -1,
          })}
          onClick={onClose}
          title="Villkor"
          to="/villkor"
        >
          Villkor
        </Link>
      </li>
    </ul>
  );
};

export default withRouter(Nav);
