import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet-async';

import { Props } from './types';
import styles from './styles.module.scss';
import LazyImage from '../../components/LazyImage';
import LazyLoad from '../../components/LazyLoad';
import ScrollTop from '../../components/ScrollTop';
import Wrapper from '../../components/Wrapper';

export default class AboutPage extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.props.load();
  }

  public render() {
    const { lobare } = this.props;
    return (
      <Wrapper>
        <h1>Om oss</h1>

        <section className={styles.row}>
          <div className={styles.col}>
            <p className="row--md">
              Ljud- och Bildgruppen, LoB, är en kommitté inom Chalmers Studentkår som hyr ut ljud- och ljusanläggningar,
              riggar och planerar ljud och ljus till kalas, dansgolv och konserter. Huvudsakligen är det studenter och
              andra kommittéer som hyr utrustning men det är inte ovanligt att även företag och privatpersoner hör av
              sig när det ska vara fest.
            </p>

            <p className="row--md">
              LoB grundades på 60-talet och har tillsammans med{' '}
              <a className="link" href="http://cffc.se" target="_blank" rel="noopener noreferrer">
                CFFC
              </a>{' '}
              sitt ursprung i DFK (Dokumentär-Film Kommittén). Länge riktades LoBs verksamhet mot bio och allt som hörde
              dit. På senare tid har denna delen av verksamheten minskat (även om vi fortfarande ansvarar för tekniken i
              Chalmers biograf) för att ge rum åt den nuvarande inriktningen mot framförallt ljud och ljus.
            </p>

            <a
              target="_blank"
              rel="noopener noreferrer"
              // eslint-disable-next-line max-len
              href="https://www.systembolaget.se/butiker-ombud/butik/vastra-gotalands-lan/goteborg/kapellplatsen-4-1412/"
            >
              <div className={styles.cat} />
            </a>
          </div>

          <div className={styles.list_row}>
            <ul className={styles.list}>
              {lobare.map((lobster) => (
                <li className={styles.item} key={lobster.image}>
                  <div className={styles.card}>
                    <LazyLoad className={styles.image_container}>
                      <LazyImage alt={lobster.name} className={styles.image} src={`/lobare/${lobster.image}`} />
                    </LazyLoad>

                    <span className={styles.name}>{lobster.name}</span>

                    <span className={styles.section}>{lobster.section}</span>

                    <p className={styles.title}>{lobster.title}</p>

                    <a className={classNames('link', styles.email)} href={`mailto:${lobster.email}`}>
                      {lobster.email}
                    </a>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </section>

        <ScrollTop />

        <Helmet title="Om oss" />
      </Wrapper>
    );
  }
}
