import React, { memo } from 'react';
import classNames from 'classnames';

import { Props } from './types';
import styles from './styles.module.scss';

const InputError: React.FC<Props> = (props) => {
  const { errors, show } = props;
  return show && errors.length > 0 ? (
    <div className={classNames(styles.error, props.className)}>{errors[0].text}</div>
  ) : (
    <div className={styles.filler} />
  );
};

export default memo(InputError);
