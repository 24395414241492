import React, { memo } from 'react';
import classNames from 'classnames';

import { Props } from './types';
import styles from './styles.module.scss';

import CheckboxIcon from '!svg-react-loader?name=CheckboxIcon!../../../assets/icons/checksmall.svg';

const Checkbox: React.FC<Props> = (props) => {
  const { checked, children, disabled, modifiers, name, type, ...rest } = props;
  const isCheckbox = type === 'checkbox';

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <label
      className={classNames(
        modifiers ? modifiers.split(' ').map((m: string) => styles[m]) : null,
        styles.block,
        props.className,
      )}
    >
      <input
        {...rest}
        checked={checked}
        disabled={disabled}
        className={classNames(styles.input, { [styles.radio]: !isCheckbox })}
        name={name}
        type={type}
        onChange={onChange}
      />
      <span role={type}>
        {isCheckbox ? (
          <CheckboxIcon alt={name} preserveAspectRatio="xMidYMid meet" className={styles.icon} />
        ) : (
          <span className={styles.dot} />
        )}
      </span>
      <span className={styles.label}>{children}</span>
    </label>
  );
};

export default memo(Checkbox);
