import { CheckoutState, Action, ActionTypes } from './types';

export const initialState: CheckoutState = {
  data: {
    student: false,
    firstName: '',
    lastName: '',
    email: '',
    telephone: '',
    startDate: undefined,
    endDate: undefined,
    message: undefined,
    terms: false,
  },
  isValid: false,
  error: false,
  state: ActionTypes.INITIAL,
};

export const reducer = (state: CheckoutState = initialState, action: Action): CheckoutState => {
  switch (action.type) {
    case ActionTypes.SUBMIT_SUCCESS:
      return {
        ...initialState,
        data: {
          ...initialState.data,
          student: state.data.student,
        },
        state: action.type,
      };
    case ActionTypes.SUBMIT_FAIL:
      return {
        ...state,
        state: action.type,
      };
    case ActionTypes.UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.key]: action.value,
        },
        state: action.type,
      };
    case ActionTypes.SET_VALID:
      return {
        ...state,
        isValid: action.isValid,
        state: action.type,
      };
    default:
      return state;
  }
};
