import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';

import { Props } from './types';
import styles from './styles.module.scss';
import { useFadeOut } from '../../hooks/fadeOut';
import { isBrowser } from '../../helpers/utils';

const Menu: React.FC<Props> = (props) => {
  const { animationDuration, as, className, children, onClose, show } = props;
  const duration = (animationDuration || 0) + 'ms';
  const elementRef = useRef<HTMLDivElement | null>(null);
  const [isMounted, setIsMounted] = useState(false);
  const shouldFadeOut = useFadeOut(show);
  const Element = as && as === 'div' ? 'div' : 'ul';

  const onKeyPress = (event: KeyboardEvent) => {
    if (event.key !== 'Escape' || event.defaultPrevented) {
      return;
    }

    event.preventDefault();
    onClose();
  };

  useEffect(() => {
    if (!isMounted && isBrowser && elementRef.current) {
      setIsMounted(true);
      elementRef.current.addEventListener('keyup', onKeyPress);
    }

    return () => {
      if (isBrowser && elementRef.current) {
        elementRef.current.removeEventListener('keyup', onKeyPress);
      }
    };
  }, []);

  return (
    <div className={styles.container} ref={elementRef}>
      <Element
        className={classNames(
          styles.menu,
          {
            [styles.show]: show,
            [styles.fade_out]: !show && shouldFadeOut,
          },
          className,
        )}
        role={Element === 'div' ? 'dialog' : 'listbox'}
        style={{
          transitionDuration: duration,
        }}
      >
        {children}
      </Element>
    </div>
  );
};

export default Menu;
