import { MiddlewareAPI, Dispatch, Middleware, AnyAction } from 'redux';
import { isPromise } from '../helpers/utils';

const promises: Array<Promise<any>> = [];

const registerPromise = (promise: Promise<any>) => {
  const alreadyInList: boolean = promises.some((p: Promise<any>) => p === promise);

  if (!alreadyInList) {
    promises.push(promise);
  }
};

const unregisterPromise = (promise: Promise<any>) => {
  const index: number = promises.findIndex((p: Promise<any>) => p === promise);

  if (index > -1) {
    promises.splice(index, 1);
  }
};

export const waitForPromises = async (): Promise<void> => {
  return Promise.all(promises).then(() => Promise.resolve());
};

export const promiseMiddleware: Middleware =
  (api: MiddlewareAPI<Dispatch<AnyAction>>) => (next: Dispatch<AnyAction>) => (action) => {
    const dispatch = api.dispatch;

    if (!action.payload) {
      if (isPromise(action)) {
        registerPromise(action);
        return action.then(dispatch).finally(() => unregisterPromise(action));
      }
    } else {
      if (isPromise(action.payload)) {
        const promise = action.payload
          .then((res: any) => dispatch({ ...action, payload: res, error: false }))
          .catch((err: any) => dispatch({ ...action, payload: err, error: true }))
          .finally(() => unregisterPromise(action.payload));

        registerPromise(promise);

        return promise;
      }
    }

    return next(action);
  };
