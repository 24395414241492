import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { getTotalQuantity } from '../../store/cart';
import { useDebounce } from '../../hooks/debounce';
import { Props } from './types';
import styles from './styles.module.scss';
import Button from '../Button';
import MenuModal from '../MenuModal';
import Nav from '../Nav';
import Wrapper from '../Wrapper';

import CartIcon from '!svg-react-loader?name=CartIcon!../../../assets/icons/shopping-cart.svg';
import HamburgerIcon from '!svg-react-loader?name=HamburgerIcon!../../../assets/icons/menu.svg';

const Header: React.FC<Props> = (props) => {
  const { className } = props;
  const qty = useSelector(getTotalQuantity);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const debouncedQty = useDebounce(qty, 150);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  useEffect(() => {
    setTotalQuantity(qty);
    setIsToggled(true);
    setTimeout(() => setIsToggled(false), 100);
  }, [debouncedQty]);

  return (
    <>
      <header className={classNames(styles.block, className)}>
        <Wrapper className={styles.wrapper}>
          <div className={styles.row}>
            <Link className={styles.link} title="Start" to="/">
              <img alt="LoB" className={styles.logo} src="/logo_large.png" srcSet="/logo.png 1x, /logo_large.png 2x" />
            </Link>

            <div className={styles.right}>
              <Nav className={styles.list} highlight={true} />

              <div className={styles.cart}>
                <Button modifiers="action large" title="Kundvagn" to="/boka">
                  <CartIcon className={styles.icon} />
                </Button>
                {totalQuantity > 0 && (
                  <div className={classNames(styles.badge, { [styles.toggled]: isToggled })}>{totalQuantity}</div>
                )}
              </div>

              <Button className={styles.hamburger} modifiers="action large" onClick={toggleMenu} title="Visa meny">
                <HamburgerIcon className={styles.icon} />
              </Button>
            </div>
          </div>
        </Wrapper>
      </header>

      <MenuModal isOpen={isMenuOpen} onClose={toggleMenu} />
    </>
  );
};

export default Header;
