import React from 'react';
import classNames from 'classnames';

import { Props } from './types';
import styles from '../styles.module.scss';

const TableBody: React.FC<Props> = (props) => {
  const { children, className } = props;
  return <tbody className={classNames(styles.body, className)}>{children}</tbody>;
};

export default TableBody;
