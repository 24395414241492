import { createSelector } from 'reselect';

import { State } from '../rootReducer';
import { CheckoutState, ActionTypes } from './types';

export const getCheckoutState = (state: State) => state.checkout;

export const getIsValid = createSelector([getCheckoutState], (s: CheckoutState) => s.isValid);
export const getIsStudent = createSelector([getCheckoutState], (s: CheckoutState) => s.data.student);
export const getIsSubmitSuccess = createSelector(
  [getCheckoutState],
  (s: CheckoutState) => s.state === ActionTypes.SUBMIT_SUCCESS,
);
export const getIsSubmitFail = createSelector(
  [getCheckoutState],
  (s: CheckoutState) => s.state === ActionTypes.SUBMIT_FAIL,
);
export const getData = createSelector([getCheckoutState], (s: CheckoutState) => s.data);
