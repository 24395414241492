import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import '../../../assets/styles/styles.scss';
import { Props } from './types';
import styles from './styles.module.scss';
import Bulletin from '../Bulletin';
import Footer from '../Footer';
import Header from '../Header';
import Popup from '../Popup';

import AboutPage from '../../pages/AboutPage';
import AspaPage from '../../pages/AspaPage';
import CategoriesPage from '../../pages/CategoriesPage';
import CheckoutPage from '../../pages/CheckoutPage';
import ContactPage from '../../pages/ContactPage';
import FrontPage from '../../pages/FrontPage';
import NotFoundPage from '../../pages/NotFoundPage';
import ProductPage from '../../pages/ProductPage';
import TermsPage from '../../pages/TermsPage';
import ThanksPage from '../../pages/ThanksPage';

const initialState = {
  isPopupOpen: false,
};

type State = typeof initialState;

export default class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = initialState;

    props.loadCart();
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.student !== this.props.student && !prevState.isPopupOpen) {
      this.setState({ isPopupOpen: true });
    }
  }

  public render() {
    const { student } = this.props;
    const { isPopupOpen } = this.state;

    return (
      <>
        <Helmet
          defaultTitle="Ljud- och Bildgruppen | LoB"
          htmlAttributes={{
            dir: 'ltr',
            lang: 'sv-SE',
          }}
          titleTemplate="%s | LoB"
        >
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
          <meta name="description" content="Hyr ljud och ljusutrustning av Ljud- och Bildgruppen Chalmers" />
          <link rel="icon" href="/favicon-32.jpg" sizes="32x32" />
          <link rel="icon" href="/favicon-64.jpg" sizes="64x64" />
          <link rel="icon" href="/favicon-128.jpg" sizes="128x128" />
          <link rel="icon" href="/favicon-152.jpg" sizes="152x152" />
          <link rel="icon" href="/favicon-167.jpg" sizes="167x167" />
          <link rel="icon" href="/favicon-180.jpg" sizes="180x180" />
          <link rel="icon" href="/favicon-192.jpg" sizes="192x192" />
          <link rel="icon" href="/favicon-196.jpg" sizes="196x196" />
          <meta name="msapplication-TileColor" content="#fff" />
          <meta name="theme-color" content="#fff" />
          <meta name="application-name" content="LoB" />
          <meta name="apple-mobile-web-app-title" content="LoB" />
          <meta property="og:site_name" content="LoB" />
          <meta property="og:title" content="LoB" />
          <meta property="og:locale" content="sv-SE" />
        </Helmet>

        <div className={styles.container}>
          <Header />

          <main className={styles.main}>
            <Switch>
              <Route exact={true} path="/" component={FrontPage} />
              <Route exact={true} path="/boka" component={CheckoutPage} />
              <Route exact={true} path="/hyra/:name" component={CategoriesPage} />
              <Route exact={true} path="/utrustning/:name" component={ProductPage} />
              <Route exact={true} path="/kontakt" component={ContactPage} />
              <Route exact={true} path="/aspa" component={AspaPage} />
              <Route exact={true} path="/om-oss" component={AboutPage} />
              <Route exact={true} path="/villkor" component={TermsPage} />
              <Route exact={true} path="/tack" component={ThanksPage} />
              <Route path="/*" component={NotFoundPage} />
            </Switch>
          </main>

          <Footer />

          <Popup
            animationDuration={150}
            closeOnBackdropClick={false}
            isOpen={isPopupOpen}
            modifiers={student ? 'success' : 'danger'}
            onClose={() => this.setState({ isPopupOpen: false })}
          >
            Alla produkter visas med {student ? 'rabatt' : 'ordinarie priser'}
          </Popup>

          <Bulletin />
        </div>
      </>
    );
  }
}
