import React from 'react';
import classNames from 'classnames';

import { Props } from './types';
import styles from './styles.module.scss';
import Dialog from '../Dialog';
import Nav from '../Nav';

const MenuModal: React.FC<Props> = (props) => {
  const { className, isOpen, onClose } = props;
  return (
    <Dialog
      animationDuration={150}
      buttons={[]}
      className={classNames(styles.dialog, className)}
      closeOnBackdropClick={false}
      dimmable={true}
      isOpen={isOpen}
      onClose={onClose}
      showCloseButton={true}
      title="Meny"
    >
      <Nav className={styles.list} onClose={onClose} />

      <div className={styles.cat} />
    </Dialog>
  );
};

export default MenuModal;
