import { createSelector } from 'reselect';

import { State } from '../rootReducer';
import { DimensionState } from './types';
import { Dimension } from '../../models/Dimension';

const getDimensionState = (state: State) => state.dimension;

export const getDimension = createSelector([getDimensionState], (s: DimensionState) => {
  const dimension: Dimension = {
    width: s.width,
    height: s.height,
  };

  return dimension;
});
