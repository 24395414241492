import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import * as cart from './cart';
import * as category from './category';
import * as checkout from './checkout';
import * as dimension from './dimension';
import * as lobare from './lobare';
import * as product from './product';
import * as routeHistory from './routeHistory';

export interface State {
  cart: cart.CartState;
  category: category.CategoryState;
  checkout: checkout.CheckoutState;
  dimension: dimension.DimensionState;
  lobare: lobare.LobareState;
  product: product.ProductState;
  routeHistory: routeHistory.RouteHistoryState;
}

export const rootState: State = {
  cart: cart.initialState,
  category: category.initialState,
  checkout: checkout.initialState,
  dimension: dimension.initialState,
  lobare: lobare.initialState,
  product: product.initialState,
  routeHistory: routeHistory.initialState,
};

export const rootReducer = (history: History<any>) =>
  combineReducers<State>({
    cart: cart.reducer,
    category: category.reducer,
    checkout: checkout.reducer,
    dimension: dimension.reducer,
    lobare: lobare.reducer,
    product: product.reducer,
    routeHistory: routeHistory.routeHistoryReducer,
    router: connectRouter(history),
  } as any);
