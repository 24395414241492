export enum ValidationError {
  EMAIL = 'email',
  MAX_LENGTH = 'maxLength',
  MIN_LENGTH = 'minLength',
  NUMBER = 'number',
  REQUIRED = 'required',
  PATTERN = 'pattern',
  TELEPHONE = 'telephone',
  TEXT = 'text',
  ZIP_CODE = 'zipCode',
}
