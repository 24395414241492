import React from 'react';

import styles from './styles.module.scss';
import ScrollTop from '../../components/ScrollTop';
import Wrapper from '../../components/Wrapper';

const NotFoundPage: React.FC = () => {
  return (
    <Wrapper as="section" className={styles.block}>
      <span className={styles.smiley}>:(</span>

      <p>Sidan kan inte hittas</p>

      <ScrollTop />
    </Wrapper>
  );
};

export default NotFoundPage;
