import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import { Props } from './types';
import styles from './styles.module.scss';
import { http } from '../../helpers/utils';
import { Bulletin as BulletinModel } from '../../models/Bulletin';
import Button from '../Button';
import Wrapper from '../Wrapper';

import CloseIcon from '!svg-react-loader?name=CartIcon!../../../assets/icons/close_x.svg';

const Bulletin: React.FC<Props> = (props) => {
  const { className, location } = props;
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    http('/api/v1/bulletin')
      .then((res: Response) => res.json())
      .then((data: { bulletin: BulletinModel | null }) => {
        if (data.bulletin) {
          setMessage(data.bulletin.message!);
        }
      });
  }, []);

  if (!message || location.pathname === '/') {
    return null;
  }

  return (
    <div className={classNames(styles.block, className)}>
      <Wrapper className={styles.wrapper}>
        {message}

        <Button className={styles.close} modifiers="action" onClick={() => setMessage(null)} title="Stäng">
          <CloseIcon />
        </Button>
      </Wrapper>
    </div>
  );
};

export default withRouter(Bulletin);
