// import { AnyAction } from 'redux';
import { connect } from 'react-redux';
// import { ThunkDispatch } from 'redux-thunk';

// import { DispatchFromProps, StateFromProps } from './types';
// import { State } from '../../store/rootReducer';
import FrontPage from './component';

// const mapStateToProps = (state: State): StateFromProps => ({

// });

// const mapDispatchToProps = (dispatch: ThunkDispatch<State, any, AnyAction>): DispatchFromProps => ({

// });

export default connect(
  null, // mapStateToProps,
  null, // mapDispatchToProps
)(FrontPage);
