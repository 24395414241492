import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { DispatchFromProps, StateFromProps } from './types';
import { State } from '../../store/rootReducer';
import { getIsPending, getCategory, load } from '../../store/category';
import { getIsStudent } from '../../store/checkout';
import { preload } from '../../store/product';
import CategoriesPage from './component';

const mapStateToProps = (state: State): StateFromProps => ({
  isPending: getIsPending(state),
  category: getCategory(state),
  student: getIsStudent(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<State, any, AnyAction>): DispatchFromProps => ({
  load: (name) => dispatch(load(name)),
  preload: (product) => dispatch(preload(product)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesPage);
