import React from 'react';
import classNames from 'classnames';

import { Props } from './types';
import styles from './styles.module.scss';
import Wrapper from '../Wrapper';

import ChevronIcon from '!svg-react-loader?name=ChevronIcon!../../../assets/icons/chevron.svg';

const Breadcrumbs: React.FC<Props> = (props) => {
  const { className } = props;

  const onClick = () => {
    if (props.previousLocation !== props.location.pathname) {
      props.history.goBack();
    } else {
      props.history.push('/hyra/ljud');
    }
  };

  return (
    <div className={classNames(styles.block, className)}>
      <Wrapper as="nav" className={styles.list}>
        <button className={styles.button} onClick={onClick}>
          <ChevronIcon className={styles.chevron} />
          <span>Tillbaka</span>
        </button>
      </Wrapper>
    </div>
  );
};

export default Breadcrumbs;
