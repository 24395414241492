export enum Day {
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
}

export enum Month {
  JANUARY,
  FEBRUARY,
  MARS,
  APRIL,
  MAY,
  JUNE,
  JULY,
  AUGUST,
  SEPTEMBER,
  OCTOBER,
  NOVEMBER,
  DECEMBER,
}

export interface CalendarDay {
  overflow: boolean;
  date: Date;
}

export interface CalendarWeek {
  days: CalendarDay[];
  weekNumber: number;
}

export interface Calendar {
  month: Month;
  year: number;
  date: Date;
  weeks: CalendarWeek[];
}

interface OwnProps {
  className?: string;
  maxDate?: Date | null;
  minDate?: Date | null;
  onChange: (date: Date) => void;
  value?: Date | null;
}

export type Props = OwnProps;
