import { createSelector } from 'reselect';

import { State } from '../rootReducer';
import { ProductState, ActionTypes } from './types';

export const getProductState = (state: State) => state.product;

export const getIsPending = createSelector([getProductState], (s: ProductState) => s.state === ActionTypes.PENDING);
export const getProduct = createSelector([getProductState], (s: ProductState) => s.data);

export const getRelatedProducts = createSelector([getProductState], (s: ProductState) => s.related);
