import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import { getCalendar, getNextMonthDate, getPreviousMonthDate } from './calendar';
import { formatDate } from '../../helpers/formatDate';
import { Props, CalendarDay, Day } from './types';
import styles from './styles.module.scss';
import Button from '../Button';

import ChevronIcon from '!svg-react-loader?name=ChevronIcon!../../../assets/icons/chevron.svg';

const Calendar: React.FC<Props> = (props) => {
  const { className, onChange, value } = props;
  const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  const [monthDate, setMonthDate] = useState(value || today);
  const [calendar, setCalendar] = useState(getCalendar(monthDate));
  const [selected, setSelected] = useState(value || today);
  const [maxDate, setMaxDate] = useState(
    props.maxDate ? new Date(props.maxDate.getFullYear(), props.maxDate.getMonth(), props.maxDate.getDate()) : null,
  );
  const [minDate, setMinDate] = useState(
    props.minDate ? new Date(props.minDate.getFullYear(), props.minDate.getMonth(), props.minDate.getDate()) : null,
  );

  const isDisabled = (date: Date): boolean => {
    const overMaxDate = maxDate ? date.getTime() > maxDate.getTime() : false;

    const belowMinDate = minDate ? date.getTime() < minDate.getTime() : false;

    return overMaxDate || belowMinDate;
  };

  const getNext = () => {
    const nextMonthDate = getNextMonthDate(monthDate);
    setMonthDate(nextMonthDate);
  };

  const getPrevious = () => {
    const previousMonthDate = getPreviousMonthDate(monthDate);
    setMonthDate(previousMonthDate);
  };

  const onDateClick = (day: CalendarDay) => {
    if (!isDisabled(day.date)) {
      if (day.overflow) {
        setMonthDate(day.date);
      }
      onChange(day.date);
    }
  };

  useEffect(() => {
    setCalendar(getCalendar(monthDate));
  }, [monthDate]);

  useEffect(() => {
    if (value) {
      setSelected(value);
    }
  }, [value]);

  useEffect(() => {
    setMaxDate(
      props.maxDate ? new Date(props.maxDate.getFullYear(), props.maxDate.getMonth(), props.maxDate.getDate()) : null,
    );
  }, [props.maxDate]);

  useEffect(() => {
    setMinDate(
      props.minDate ? new Date(props.minDate.getFullYear(), props.minDate.getMonth(), props.minDate.getDate()) : null,
    );
  }, [props.minDate]);

  return (
    <div className={classNames(styles.block, className)}>
      <div className={styles.bar}>
        <Button className={styles.close} modifiers="action large" onClick={getPrevious} title="Föregående månad">
          <ChevronIcon className={classNames(styles.chevron, styles.chevron_left)} />
        </Button>

        <span className={styles.title}>{formatDate(calendar.date, 'MMMM')}</span>

        <Button className={styles.close} modifiers="action large" onClick={getNext} title="Nästa månad">
          <ChevronIcon className={styles.chevron} />
        </Button>
      </div>

      <div className={styles.row}>
        <div className={styles.cell}>
          <div className={styles.cell_head}>M</div>
        </div>
        <div className={styles.cell}>
          <div className={styles.cell_head}>T</div>
        </div>
        <div className={styles.cell}>
          <div className={styles.cell_head}>O</div>
        </div>
        <div className={styles.cell}>
          <div className={styles.cell_head}>T</div>
        </div>
        <div className={styles.cell}>
          <div className={styles.cell_head}>F</div>
        </div>
        <div className={styles.cell}>
          <div className={styles.cell_head}>L</div>
        </div>
        <div className={styles.cell}>
          <div className={styles.cell_head}>S</div>
        </div>
      </div>

      {calendar.weeks.map((week) => (
        <div className={styles.row} key={week.weekNumber}>
          {week.days.map((day, i) => (
            <div className={styles.cell} key={day.date.getTime()}>
              <button
                className={classNames(styles.cell_inner, {
                  [styles.overflow]: day.overflow,
                  [styles.selected]: day.date.getTime() === selected.getTime(),
                })}
                disabled={isDisabled(day.date) || i === Day.SUNDAY}
                onClick={() => onDateClick(day)}
              >
                <span className={styles.digit}>{formatDate(day.date, 'd')}</span>
              </button>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Calendar;
