import { LOCATION_CHANGE } from 'connected-react-router';

import { RouteHistoryState, RouteHistoryAction } from './types';

export const initialState: RouteHistoryState = {
  currentLocation: null,
  previousLocation: null,
};

export const routeHistoryReducer = (
  state: RouteHistoryState = initialState,
  action: RouteHistoryAction,
): RouteHistoryState => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        previousLocation: state.currentLocation,
        currentLocation: action.payload.location.pathname,
      };
    default:
      return state;
  }
};
