import React, { memo } from 'react';
import classNames from 'classnames';

import { Props } from './types';
import styles from './styles.module.scss';
import Nav from '../Nav';
import Wrapper from '../Wrapper';

const Footer: React.FC<Props> = (props) => {
  const { className } = props;

  return (
    <footer className={classNames(styles.block, className)}>
      <Wrapper className={styles.small}>
        <span className={styles.copyright}>© Ljud- och Bildgruppen Chalmers Studentkår {new Date().getFullYear()}</span>

        <Nav className={styles.nav} />
      </Wrapper>
    </footer>
  );
};

export default memo(Footer);
