import { Action as HTTPAction } from '../types';
import { Product } from '../../models/Product';

export enum ActionTypes {
  PENDING = 'product/PENDING',
  LOADING = 'product/LOADING',
  LOADED = 'product/LOADED',
  LOADING_RELATED = 'product/LOADING_RELATED',
  LOADED_RELATED = 'product/LOADED_RELATED',
  PRELOADED = 'product/PRELOADED',
}

export interface ProductState {
  data: Product | null;
  related: Product[];
  error: boolean;
  state: ActionTypes;
}

interface PendingAction {
  type: ActionTypes.PENDING;
}

interface LoadingAction {
  type: ActionTypes.LOADING;
}

interface LoadedAction extends HTTPAction {
  type: ActionTypes.LOADED;
  payload: Product;
}

interface LoadingRelatedAction {
  type: ActionTypes.LOADING_RELATED;
}

interface LoadedRelatedAction extends HTTPAction {
  type: ActionTypes.LOADED_RELATED;
  payload: Product[];
}

interface PreloadedAction {
  type: ActionTypes.PRELOADED;
  payload: Product;
}

export type Action =
  | PendingAction
  | LoadingAction
  | LoadedAction
  | LoadingRelatedAction
  | LoadedRelatedAction
  | PreloadedAction;
