import React from 'react';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';
import ScrollTop from '../../components/ScrollTop';
import Wrapper from '../../components/Wrapper';

const ThanksPage: React.FC = () => {
  return (
    <Wrapper as="section" className={styles.block}>
      <h1>Tack för din bokning!</h1>

      <p>Vi återkommer så snart som möjligt med en bokningsbekräftelse!</p>

      <p>Vi har öppet måndag-lördag mellan 12:00-12:30.</p>

      <div className={styles.cat} />

      <Link className={styles.link} to="/" title="Start">
        Tillbaka till start
      </Link>

      <ScrollTop />
    </Wrapper>
  );
};

export default ThanksPage;
