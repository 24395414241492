import { Dispatch, AnyAction } from 'redux';

import { Product } from '../../models/Product';
import { http } from '../../helpers/utils';
import { ActionTypes } from './types';

export const load = (url: string) => (dispatch: Dispatch<AnyAction>) => {
  dispatch({
    type: ActionTypes.LOADING,
  });

  dispatch({
    type: ActionTypes.LOADED,
    payload: http(`/api/v1/products/${url}`).then((res: Response) => res.json()),
  });
};

export const preload = (product: Product) => (dispatch: Dispatch<AnyAction>) => {
  dispatch({
    type: ActionTypes.PRELOADED,
    payload: product,
  });
};

export const loadRelated = (url: string) => (dispatch: Dispatch<AnyAction>) => {
  dispatch({
    type: ActionTypes.LOADING_RELATED,
  });

  dispatch({
    type: ActionTypes.LOADED_RELATED,
    payload: http(`/api/v1/products/${url}/related`).then((res: Response) => res.json()),
  });
};
