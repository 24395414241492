import { ProductState, Action, ActionTypes } from './types';

export const initialState: ProductState = {
  data: null,
  related: [],
  error: false,
  state: ActionTypes.PENDING,
};

export const reducer = (state: ProductState = initialState, action: Action): ProductState => {
  switch (action.type) {
    case ActionTypes.PENDING:
    case ActionTypes.LOADING:
      return {
        ...state,
        state: action.type,
      };
    case ActionTypes.LOADING_RELATED:
      return {
        ...state,
        related: [],
        state: action.type,
      };
    case ActionTypes.LOADED:
      return {
        ...state,
        data: action.error ? state.data : action.payload,
        error: action.error,
        state: action.type,
      };
    case ActionTypes.LOADED_RELATED:
      return {
        ...state,
        related: action.error ? state.related : action.payload,
        error: action.error,
        state: action.type,
      };
    case ActionTypes.PRELOADED:
      return {
        ...state,
        data: action.payload,
        error: false,
        state: action.type,
      };
    default:
      return state;
  }
};
