import React, { useState } from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet-async';

import { Props } from './types';
import styles from './styles.module.scss';
import { http } from '../../helpers/utils';
import { useFieldState } from '../../hooks/fieldState';
import { useFormValidation } from '../../hooks/formValidation';
import { Asp } from '../../models/Asp';
import { Validators } from '../../models/Validators';
import Button from '../../components/Button';
import Dialog from '../../components/Dialog';
import Input from '../../components/Input';
import ScrollTop from '../../components/ScrollTop';
import Wrapper from '../../components/Wrapper';

const AspaPage: React.FC<Props> = (props) => {
  const [fail, setIsFail] = useState(false);
  const [success, setIsSuccess] = useState(false);
  const [fields, setFields] = useFieldState({
    name: {
      value: '',
      validators: [Validators.required()],
    },
    email: {
      value: '',
      validators: [Validators.required(), Validators.email()],
    },
    telephone: {
      value: '',
      validators: [Validators.required(), Validators.telephone()],
    },
    message: {
      value: '',
      validators: [],
    },
  });
  const isValid = useFormValidation(fields);

  const onChange = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    setFields(name, value);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const asp: Asp = {
      name: fields.name.value,
      email: fields.email.value,
      telephone: fields.telephone.value,
      message: fields.message.value,
    };

    if (isValid) {
      http('/api/v1/aspa', {
        method: 'POST',
        body: JSON.stringify({ asp }),
      })
        .then(() => setIsSuccess(true))
        .catch(() => setIsFail(true));
    }
  };

  const onSuccessClose = () => {
    setIsSuccess(false);
    props.history.push('/');
  };

  return (
    <Wrapper>
      <h1>Aspa</h1>

      <section className={styles.row}>
        <div className={styles.col}>
          <p className="row--md">
            Som asp får du möjlighet att testa på hur det är att vara med i Ljud- och Bildgruppen! Det innebär att rigga
            både stora och små saker, allt ifrån köra konserter i väst till tokfester i öst.
          </p>

          <p className="row--md">
            Är du orolig över att inte ha någon kunskap/erfarenhet av att sköta ljud och ljus så behöver du inte vara
            rädd, aspningen är till för att du ska lära dig och ha kul!
          </p>

          <p className="row--md">
            Låter detta lite intressant? Skicka iväg ett mail med lite kluriga frågor eller kom helt enkelt och hälsa på
            oss, ni finner oss längst ner i kårhuset, dvs källaren.
          </p>
        </div>

        <form className={styles.col} onSubmit={onSubmit}>
          <Input
            className="row--xs"
            label="Namn"
            name="name"
            onChange={onChange}
            type="text"
            validators={fields.name.validators}
            value={fields.name.value}
          />

          <Input
            className="row--xs"
            label="E-post"
            name="email"
            onChange={onChange}
            type="email"
            validators={fields.email.validators}
            value={fields.email.value}
          />

          <Input
            className="row--xs"
            label="Telefonnummer"
            name="telephone"
            onChange={onChange}
            type="tel"
            validators={fields.telephone.validators}
            value={fields.telephone.value}
          />

          <Input
            className="row--xs"
            element="textarea"
            label="Meddelande"
            name="message"
            onChange={onChange}
            type="text"
            validators={fields.message.validators}
            value={fields.message.value}
          />

          <Button
            className={classNames('row--md', styles.submit)}
            disabled={!isValid}
            modifiers="primary"
            title="Kom igen, du vågar!"
            type="submit"
          >
            Skicka
          </Button>
        </form>
      </section>

      <Dialog
        animationDuration={150}
        className={styles.dialog}
        closeOnBackdropClick={true}
        dimmable={true}
        isOpen={success && !fail}
        modifiers="light"
        onClose={onSuccessClose}
        showCloseButton={true}
        title="Tack för ditt meddelande!"
      >
        Kul att du vill aspa, vi ser fram emot att lära känna dig!
      </Dialog>

      <Dialog
        animationDuration={150}
        className={styles.dialog}
        closeOnBackdropClick={true}
        dimmable={true}
        isOpen={!success && fail}
        modifiers="light"
        onClose={() => setIsFail(false)}
        showCloseButton={true}
        title="Fel"
      >
        Kunde inte skicka formulär. Försök igen senare.
      </Dialog>

      <ScrollTop />

      <Helmet title="Aspa" />
    </Wrapper>
  );
};

export default AspaPage;
