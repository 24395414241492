/* eslint-disable @typescript-eslint/member-ordering */
import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet-async';
import { Product as SchemaProduct } from 'schema-dts';

import { formatPrice } from '../../helpers/formatPrice';
import { Props } from './types';
import styles from './styles.module.scss';
import Breadcrumbs from '../../components/Breadcrumbs';
import Button from '../../components/Button';
import CheckMark from '../../components/CheckMark';
import LazyImage from '../../components/LazyImage';
import LazyLoad from '../../components/LazyLoad';
import ProductsList from '../../components/ProductsList';
import ScrollTop from '../../components/ScrollTop';
import Spinner from '../../components/Spinner';
import Wrapper from '../../components/Wrapper';
import Checkbox from '../../components/Checkbox';

const initialState = {
  isLoading: false,
  strobe: false,
};

type State = typeof initialState;

export default class ProductPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = initialState;
    this.loadData();
  }

  private loadData = () => {
    setTimeout(() => {
      const name = this.props.location.pathname.replace('/utrustning/', '');
      this.props.load(name);
      this.props.loadProducts(name);
    }, 0);
  };

  private onClick = () => {
    if (this.props.product && !this.state.isLoading) {
      this.setState({ isLoading: true });
      setTimeout(() => {
        this.props.setQty(this.props.product!, 1);
        setTimeout(() => this.setState({ isLoading: false }), 200);
      }, 850);
    }
  };

  private onEasterClick = () => {
    if (this.props.product && this.props.product.id === 11) {
      this.setState({ strobe: true });
      setTimeout(() => {
        this.setState({ strobe: false });
      }, 1000);
    }
  };

  public onCheckboxChange = (event: React.ChangeEvent<any>) => {
    const { name, checked } = event.target;
    this.props.update(name, checked);
  };

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.loadData();
    }
  }

  public render() {
    const { preload, product, relatedProducts, student } = this.props;
    const { isLoading, strobe } = this.state;

    if (!product) {
      return null;
    }

    const normalPrice = student ? product.prices.student : product.prices.normal;

    const xForPrice = product.xForPrice
      ? student
        ? product.xForPrice.prices.student
        : product.xForPrice.prices.normal
      : null;

    return (
      <>
        <Breadcrumbs />

        <Wrapper className={classNames(styles.block, { [styles.strobe]: strobe })}>
          <section className={styles.row}>
            <div className={styles.image_col} onClick={this.onEasterClick}>
              <LazyLoad className={styles.image_container}>
                <LazyImage
                  alt={product.image ? product.name : 'Bild saknas'}
                  className={classNames(styles.image, { [styles.no_image]: !product.image })}
                  src={product.image || '/katt.png'}
                />
              </LazyLoad>
            </div>

            <div className={styles.text_col}>
              <h1 className="row--md">{product.name}</h1>

              <div className={styles.price}>
                <span className={styles.normal}>
                  {formatPrice(normalPrice)}/{product.unit}
                </span>
                {xForPrice && (
                  <span className={styles.discount}>
                    {product.xForPrice!.x} för {formatPrice(xForPrice)}/{product.unit}
                  </span>
                )}
              </div>

              <p className={styles.description} dangerouslySetInnerHTML={{ __html: product.description }} />

              <ul className="row--md">
                <li className={styles.cons_item}>
                  <CheckMark className={styles.checkmark} />
                  All utrustning testas efter varje uthyrning
                </li>
                <li className={styles.cons_item}>
                  <CheckMark className={styles.checkmark} />
                  Hämtas på plats
                </li>
                <li className={styles.cons_item}>
                  <CheckMark className={styles.checkmark} />
                  Betalas med kort
                </li>
              </ul>

              <Checkbox
                className="row--xs"
                checked={student}
                name="student"
                onChange={this.onCheckboxChange}
                type="checkbox"
              >
                Kårmedlem? Studenter har rabatt.
              </Checkbox>

              <Button className={styles.button} modifiers="primary block large rounded" onClick={this.onClick}>
                {isLoading ? <Spinner /> : 'Lägg till i bokning'}
              </Button>
            </div>
          </section>

          {relatedProducts.length > 0 && (
            <section className={styles.related}>
              <h2>Relaterat</h2>

              <ProductsList preload={preload} products={relatedProducts} student={student} />
            </section>
          )}
        </Wrapper>

        <ScrollTop />

        <Helmet title={product ? product.name : undefined} />
      </>
    );
  }
}
