import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { formatPrice } from '../../helpers/formatPrice';
import { Props } from './types';
import styles from './styles.module.scss';
import LazyImage from '../LazyImage';
import LazyLoad from '../LazyLoad';

const ProductCard: React.FC<Props> = (props) => {
  const { className, preload, product, student } = props;

  const price = student ? product.prices.student : product.prices.normal;

  return (
    <Link
      className={classNames(styles.block, className)}
      onClick={() => preload(product)}
      to={'/utrustning/' + product.url}
    >
      <div className={styles.row}>
        <span className={styles.title}>{product.name}</span>

        <span className={styles.price}>
          {formatPrice(price)}/{product.unit}
        </span>
      </div>

      <LazyLoad className={styles.image_container}>
        <LazyImage
          alt={product.image ? product.name : 'Bild saknas'}
          className={classNames(styles.image, { [styles.no_image]: !product.image })}
          src={product.image || '/katt.png'}
        />
      </LazyLoad>

      <div className={styles.footer}>
        <span className={styles.description}>{product.short}</span>
      </div>
    </Link>
  );
};

export default ProductCard;
