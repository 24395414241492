import { Dispatch, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { ActionTypes } from './types';
import { load } from '../cart';
import { State } from '../rootReducer';
import { http } from '../../helpers/utils';

export const update = (key: string, value: any) => (dispatch: ThunkDispatch<State, any, AnyAction>) => {
  dispatch({
    type: ActionTypes.UPDATE,
    key,
    value,
  });

  if (key === 'student') {
    // re-calculate total price
    dispatch(load());
  }
};

export const setValid = (isValid: boolean) => (dispatch: Dispatch<AnyAction>, getState: () => State) => {
  const state = getState();
  const cart = state.cart.data;

  dispatch({
    type: ActionTypes.SET_VALID,
    isValid: cart.items.length > 0 && isValid,
  });
};

export const submit = () => (dispatch: Dispatch<AnyAction>, getState: () => State) => {
  const state = getState();
  const cart = state.cart.data;
  const checkout = state.checkout.data;

  http('/api/v1/booking', {
    method: 'POST',
    body: JSON.stringify({
      cart,
      checkout,
    }),
  })
    .then(() => {
      dispatch({
        type: ActionTypes.SUBMIT_SUCCESS,
      });
    })
    .catch(() => {
      dispatch({
        type: ActionTypes.SUBMIT_FAIL,
      });
    });
};
