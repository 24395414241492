import React from 'react';
import classNames from 'classnames';

import { Props } from './types';
import styles from '../styles.module.scss';

const TableHead: React.FC<Props> = (props) => {
  const { children, className } = props;
  return <thead className={classNames(styles.header, className)}>{children}</thead>;
};

export default TableHead;
