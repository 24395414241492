import { CategoryState, Action, ActionTypes } from './types';

export const initialState: CategoryState = {
  data: {
    description: '',
    category: 'ljud',
    count: 0,
    products: [],
  },
  error: false,
  state: ActionTypes.PENDING,
};

export const reducer = (state: CategoryState = initialState, action: Action): CategoryState => {
  switch (action.type) {
    case ActionTypes.PENDING:
    case ActionTypes.LOADING:
      return {
        ...state,
        state: action.type,
      };
    case ActionTypes.LOADED:
      return {
        data: action.error ? state.data : action.payload,
        error: action.error,
        state: action.type,
      };
    default:
      return state;
  }
};
