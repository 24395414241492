import { DimensionState, DimensionAction, ActionTypes } from './types';

export const initialState: DimensionState = {
  width: 0,
  height: 0,
};

export const reducer = (state: DimensionState = initialState, action: DimensionAction): DimensionState => {
  switch (action.type) {
    case ActionTypes.SET:
      return {
        width: action.width,
        height: action.height,
      };
    default:
      return state;
  }
};
