import { Dispatch, AnyAction } from 'redux';

import { http } from '../../helpers/utils';
import { ActionTypes } from './types';

export const load = (name: string) => (dispatch: Dispatch<AnyAction>) => {
  dispatch({
    type: ActionTypes.LOADING,
  });

  dispatch({
    type: ActionTypes.LOADED,
    payload: http(`/api/v1/categories/${name}`).then((res: Response) => res.json()),
  });
};
