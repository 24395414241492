import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet-async';

import { Props } from './types';
import styles from './styles.module.scss';
import ProductsList from '../../components/ProductsList';
import ScrollMenu from '../../components/ScrollMenu';
import ScrollTop from '../../components/ScrollTop';
import Wrapper from '../../components/Wrapper';

export default class CategoriesPage extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.loadData();
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.loadData();
    }
  }

  public render() {
    const { category, preload, student } = this.props;
    return (
      <>
        <ScrollMenu />

        <div className={styles.description}>
          <p>{category.description}</p>
        </div>

        <Wrapper as="section">
          <ProductsList preload={preload} products={category.products} student={student} />
        </Wrapper>

        <ScrollTop />

        <Helmet title="Hyra" />
      </>
    );
  }

  private loadData = () => {
    setTimeout(() => {
      const name = this.props.location.pathname.replace('/hyra/', '');
      if (this.props.isPending || (this.props.category && this.props.category.category !== name)) {
        this.props.load(name || 'ljud');
      }
    }, 0);
  };
}
