import React, { useState, useEffect, memo } from 'react';
import classNames from 'classnames';

import { Props } from './types';
import styles from './styles.module.scss';
import InputError from '../InputError';
import { useFieldErrors } from '../../hooks/fieldErrors';

const Input: React.FC<Props> = (props) => {
  const val = (props.value || '').toString();
  const { className, element, floatable, label, modifiers, placeholder, required, showError, validators, ...rest } =
    props;
  const [isDirty, setIsDirty] = useState(false);
  const [isFloating, setIsFloating] = useState(!!val);
  const [value, setValue] = useState(val);
  const errors = useFieldErrors(val, validators);
  const invalid = !!showError && isDirty && errors.length > 0;
  const Element = element === 'textarea' ? 'textarea' : 'input';

  const onBlur = (event: React.FocusEvent<any>) => {
    setIsFloating(!!value);
    setIsDirty(true);

    if (props.onBlur) {
      props.onBlur(event);
    }
  };

  const onChange = (event: React.ChangeEvent<any>) => {
    setValue(event.target.value);

    if (props.onChange) {
      props.onChange(event);
    }
  };

  const onFocus = (event: React.FocusEvent<any>) => {
    setIsFloating(true);

    if (props.onFocus) {
      props.onFocus(event);
    }
  };

  useEffect(() => {
    const propValue = props.value === undefined || props.value === null ? '' : props.value;

    setValue(propValue.toString());
  }, [props.value]);

  return (
    <div
      className={classNames(
        modifiers ? modifiers.split(' ').map((m: string) => styles[m]) : null,
        styles.block,
        className,
        { [styles.floating]: !!floatable },
      )}
    >
      {label && (
        <label
          className={classNames(styles.label, {
            [styles.invalid]: invalid,
            [styles.floating]: !!floatable,
            [styles.static]: !isFloating,
          })}
          htmlFor={props.name}
        >
          {label}
        </label>
      )}
      <Element
        className={classNames(styles.input, {
          [styles.invalid]: invalid,
          [styles.textarea]: element === 'textarea',
        })}
        id={props.name}
        {...rest}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        placeholder={floatable ? '' : placeholder}
        required={required}
        title={label}
        value={value}
      />

      <InputError errors={errors} show={!!showError || isDirty} />
    </div>
  );
};

export default memo(Input);
