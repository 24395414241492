import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { isBrowser } from '../../helpers/utils';
import { Props } from './types';
import Backdrop from '../Backdrop';
import Layer from '../Layer';
import styles from './styles.module.scss';

const Modal: React.FC<Props> = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const { closeOnBackdropClick, component, container, dimmable, onClose, isOpen, ...rest } = props;
  const animationDuration = props.animationDuration || 0;
  const Component = component;
  const show = isOpen && isVisible;
  let enterAnimation: number;
  let leaveAnimation: NodeJS.Timer;

  const clearTimers = () => {
    if (enterAnimation) {
      cancelAnimationFrame(enterAnimation);
    }

    if (leaveAnimation) {
      clearTimeout(leaveAnimation);
    }
  };

  const didClose = () => {
    leaveAnimation = setTimeout(() => {
      setIsVisible(false);
    }, animationDuration);
  };

  const didOpen = () => {
    clearTimers();
    enterAnimation = requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        setIsVisible(true);
      });
    });
  };

  const onBackdropClick = () => {
    if (closeOnBackdropClick) {
      onClose();
    }
  };

  const onDocumentKeyPress = (event: KeyboardEvent) => {
    if (event.key !== 'Escape' || event.defaultPrevented) {
      return;
    }

    onClose();
  };

  const addDOMListener = () => {
    if (isBrowser) {
      document.addEventListener('keyup', onDocumentKeyPress);
    }
  };

  const removeDOMListener = () => {
    if (isBrowser) {
      document.removeEventListener('keyup', onDocumentKeyPress);
    }
  };

  const restoreScroll = (body: HTMLBodyElement) => {
    const scrollY = body.style.top;
    body.style.position = '';
    body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
  };

  useEffect(() => {
    if (isBrowser && dimmable) {
      const element = document.querySelector('body');
      const className = 'noscroll';

      if (element) {
        if (isVisible) {
          element.style.top = `-${window.scrollY}px`;
          element.style.position = 'fixed';
          element.classList.add(className);
        } else {
          restoreScroll(element);
          element.classList.remove(className);
        }
      }

      return () => {
        if (element) {
          element.classList.remove(className);
        }
      };
    }

    return;
  }, [isVisible]);

  useEffect(() => {
    if (isBrowser) {
      if (isOpen) {
        addDOMListener();
        didOpen();
      } else {
        removeDOMListener();
        didClose();
      }
    }
  }, [props.isOpen]);

  useEffect(() => {
    return () => {
      clearTimers();
      removeDOMListener();
    };
  }, []);

  if (!isOpen && !isVisible) {
    return null;
  }

  return (
    <Layer appendTo={container}>
      <div
        className={classNames(styles.block, {
          [styles.is_open]: isOpen,
        })}
      >
        {(dimmable || closeOnBackdropClick) && (
          <Backdrop animationDuration={animationDuration} dimmable={dimmable} dimmed={show} onClick={onBackdropClick} />
        )}

        <Component onClose={onClose} show={show} {...rest} />
      </div>
    </Layer>
  );
};

export default Modal;
