import { Cart } from '../../models/Cart';

export enum ActionTypes {
  PENDING = 'cart/PENDING',
  LOADING = 'cart/LOADING',
  LOADED = 'cart/LOADED',
  CLEAR = 'cart/CLEAR',
  SET_QTY = 'cart/SET_QTY',
}

export interface CartState {
  data: Cart;
  state: ActionTypes;
}

interface PendingAction {
  type: ActionTypes.PENDING;
}

interface ClearAction {
  type: ActionTypes.CLEAR;
}

interface LoadingAction {
  type: ActionTypes.LOADING;
}

interface LoadedAction {
  type: ActionTypes.LOADED;
  payload: Cart;
}

interface SetQtyAction {
  type: ActionTypes.SET_QTY;
  payload: Cart;
}

export type Action = PendingAction | ClearAction | LoadingAction | LoadedAction | SetQtyAction;
