import { Dispatch } from 'redux';

import { ActionTypes, DimensionAction } from './types';

export const setDimension = (width: number, height: number) => (dispatch: Dispatch<DimensionAction>) => {
  dispatch({
    type: ActionTypes.SET,
    width,
    height,
  });
};
