import { Calendar, CalendarDay, CalendarWeek, Day, Month } from './types';

export const getDaysInMonth = (date: Date): number => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
};

export const getPreviousMonthDate = (date: Date): Date => {
  let previousYear: number = date.getFullYear();
  let previousMonth: number = date.getMonth() - 1;

  if (previousMonth < Month.JANUARY) {
    previousMonth = Month.DECEMBER;
    previousYear--;
  }

  return new Date(previousYear, previousMonth);
};

export const getNextMonthDate = (date: Date): Date => {
  let nextYear: number = date.getFullYear();
  let nextMonth: number = date.getMonth() + 1;

  if (nextMonth > Month.DECEMBER) {
    nextMonth = Month.JANUARY;
    nextYear++;
  }

  return new Date(nextYear, nextMonth);
};

export const getPreviousYearDate = (date: Date): Date => {
  const previousYear: number = date.getFullYear() - 1;
  const currentMonth: number = date.getMonth();

  return new Date(previousYear, currentMonth);
};

export const getNextYearDate = (date: Date): Date => {
  const nextYear: number = date.getFullYear() + 1;
  const currentMonth: number = date.getMonth();

  return new Date(nextYear, currentMonth);
};

export const getFirstDayInMonth = (date: Date): Day => {
  const currentDate: Date = new Date(date.getFullYear(), date.getMonth(), 1);
  return (currentDate.getDay() + 6) % 7;
};

export const getWeekNumber = (date: Date): number => {
  const currentDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  currentDate.setUTCDate(currentDate.getUTCDate() + 4 - (currentDate.getUTCDay() || 7));

  const yearStart = new Date(Date.UTC(currentDate.getUTCFullYear(), 0, 1));
  const weekNumber = Math.ceil(((currentDate.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
  return weekNumber;
};

export const getCalendar = (date: Date): Calendar => {
  const currentDate: Date = new Date(date.getFullYear(), date.getMonth());
  const previousMonthDate: Date = getPreviousMonthDate(currentDate);
  const nextMonthDate: Date = getNextMonthDate(currentDate);
  const daysInCurrentMonth: number = getDaysInMonth(currentDate);
  const daysInPreviousMonth: number = getDaysInMonth(previousMonthDate);
  const firstDay: Day = getFirstDayInMonth(currentDate);
  const weeks: CalendarWeek[] = [];

  let previousMonthDayCounter: number = daysInPreviousMonth - firstDay + 1;
  let monthDayCounter = 1;
  let nextMonthDayCounter = 1;
  // const weekNumberCounter: number = getWeekNumber(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));

  for (let row = 0; row < 6; row++) {
    const days: CalendarDay[] = [];
    let currentWeekNumber = -1;

    for (let day = Day.MONDAY; day <= Day.SUNDAY; day++) {
      let monthDate: Date = currentDate;
      let dayNumber: number;
      let overflow = true;

      if (previousMonthDayCounter <= daysInPreviousMonth) {
        monthDate = previousMonthDate;
        dayNumber = previousMonthDayCounter++;
      } else if (monthDayCounter <= daysInCurrentMonth) {
        overflow = false;
        dayNumber = monthDayCounter++;
      } else {
        monthDate = nextMonthDate;
        dayNumber = nextMonthDayCounter++;
      }

      const dayDate: Date = new Date(monthDate.getFullYear(), monthDate.getMonth(), dayNumber);

      if (day === Day.MONDAY) {
        currentWeekNumber = getWeekNumber(dayDate);
      }

      days.push({
        overflow,
        date: dayDate,
      });
    }

    weeks.push({
      days,
      weekNumber: currentWeekNumber,
    });
  }

  const calendar = {
    month: currentDate.getMonth(),
    year: currentDate.getFullYear(),
    date: currentDate,
    weeks,
  };

  return calendar;
};
