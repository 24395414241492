import { Action as HTTPAction } from '../types';
import { Lobare } from '../../models/Lobare';

export enum ActionTypes {
  PENDING = 'lobare/PENDING',
  LOADING = 'lobare/LOADING',
  LOADED = 'lobare/LOADED',
}

export interface LobareState {
  data: Lobare[];
  error: boolean;
  state: ActionTypes;
}

interface PendingAction {
  type: ActionTypes.PENDING;
}

interface LoadingAction {
  type: ActionTypes.LOADING;
}

interface LoadedAction extends HTTPAction {
  type: ActionTypes.LOADED;
  payload: Lobare[];
}

export type Action = PendingAction | LoadingAction | LoadedAction;
