import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';

import { Props } from './types';
import styles from './styles.module.scss';
import Wrapper from '../Wrapper';

const ScrollMenu: React.FC<Props> = (props) => {
  const { className, location } = props;

  return (
    <div className={classNames(styles.block, className)}>
      <Wrapper as="ul" className={styles.list}>
        <li className={styles.item}>
          <Link
            className={classNames(styles.link, { [styles.active]: location.pathname.indexOf('/ljud') > -1 })}
            to="/hyra/ljud"
          >
            Ljud
          </Link>
        </li>

        <li className={styles.item}>
          <Link
            className={classNames(styles.link, { [styles.active]: location.pathname.indexOf('/ljus') > -1 })}
            to="/hyra/ljus"
          >
            Ljus
          </Link>
        </li>

        <li className={styles.item}>
          <Link
            className={classNames(styles.link, { [styles.active]: location.pathname.indexOf('/bild') > -1 })}
            to="/hyra/bild"
          >
            Bild
          </Link>
        </li>

        <li className={styles.item}>
          <Link
            className={classNames(styles.link, { [styles.active]: location.pathname.indexOf('/paket') > -1 })}
            to="/hyra/paket"
          >
            Paket
          </Link>
        </li>

        <li className={styles.item}>
          <Link
            className={classNames(styles.link, { [styles.active]: location.pathname.indexOf('/tillbehor') > -1 })}
            to="/hyra/tillbehor"
          >
            Tillbehör
          </Link>
        </li>
      </Wrapper>
    </div>
  );
};

export default withRouter(ScrollMenu);
