import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { getIsStudent } from '../../store/checkout';
import { formatPrice } from '../../helpers/formatPrice';
import { productPrice } from '../../helpers/productPrice';
import { Props } from './types';
import styles from './styles.module.scss';
import Button from '../Button';
import LazyImage from '../LazyImage';
import LazyLoad from '../LazyLoad';

import MinusIcon from '!svg-react-loader?name=MinusIcon!../../../assets/icons/minus.svg';
import PlusIcon from '!svg-react-loader?name=PlusIcon!../../../assets/icons/plus.svg';

const ProductItem: React.FC<Props> = (props) => {
  const { className, item, preload, setQty } = props;
  const student = useSelector(getIsStudent);
  const discountPrice = productPrice(item.product, item.qty, student);
  const normalPrice = student ? item.product.prices.student * item.qty : item.product.prices.normal * item.qty;
  const totalDiscount = normalPrice - discountPrice;

  const decrease = () => {
    if (item.qty > 0) {
      setQty(item.product, -1);
    }
  };

  const increase = () => setQty(item.product, 1);

  return (
    <div className={classNames(styles.block, className)}>
      <Link onClick={() => preload(item.product)} to={'/utrustning/' + item.product.url}>
        <LazyLoad className={styles.image_container}>
          <LazyImage
            alt={item.product.image ? item.product.name : 'Bild saknas'}
            className={classNames(styles.image, { [styles.no_image]: !item.product.image })}
            src={item.product.image || '/katt.png'}
          />
        </LazyLoad>
      </Link>

      <div className={styles.content}>
        <Link className={styles.title} onClick={() => preload(item.product)} to={'/utrustning/' + item.product.url}>
          {item.product.name}
        </Link>

        <span className={styles.description}>{item.product.short}</span>

        <div className={styles.price}>
          <span className={classNames(styles.normal, { [styles.has_discount]: totalDiscount > 0 })}>
            {formatPrice(normalPrice)}
          </span>
          {totalDiscount > 0 && <span className={styles.discount}>{formatPrice(discountPrice)}</span>}
        </div>
      </div>

      <div className={styles.actions}>
        <Button disabled={item.qty <= 0} modifiers="action large" onClick={decrease} title="Ta bort">
          <MinusIcon className={styles.icon} />
        </Button>

        <span className={styles.qty}>{item.qty}</span>

        <Button modifiers="action large" onClick={increase} title="Lägg till">
          <PlusIcon className={styles.icon} />
        </Button>
      </div>
    </div>
  );
};

export default ProductItem;
