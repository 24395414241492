import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { Props } from './types';
import { isBrowser } from '../../helpers/utils';

const Layer: React.FC<Props> = (props) => {
  const { appendTo } = props;
  const [container, setContainer] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (isBrowser) {
      const containerElement = appendTo || document.getElementById('modal-root');

      if (containerElement) {
        setContainer(containerElement);
      }
    }
  }, [appendTo]);

  return container ? ReactDOM.createPortal(props.children, container) : null;
};

export default Layer;
