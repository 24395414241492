import { Checkout } from '../../models/Checkout';

export enum ActionTypes {
  INITIAL = 'checkout/INITIAL',
  UPDATE = 'checkout/UPDATE',
  SET_VALID = 'checkout/SET_VALID',
  SUBMIT_SUCCESS = 'checkout/SUBMIT_SUCCESS',
  SUBMIT_FAIL = 'checkout/SUBMIT_FAIL',
}

export interface CheckoutState {
  data: Checkout;
  isValid: boolean;
  error: boolean;
  state: ActionTypes;
}

interface UpdateAction {
  type: ActionTypes.UPDATE;
  key: string;
  value: any;
}

interface SetValidAction {
  type: ActionTypes.SET_VALID;
  isValid: boolean;
}

interface SubmitSuccess {
  type: ActionTypes.SUBMIT_SUCCESS;
}

interface SubmitFail {
  type: ActionTypes.SUBMIT_FAIL;
}

export type Action = UpdateAction | SetValidAction | SubmitSuccess | SubmitFail;
