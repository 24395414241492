import React from 'react';
import classNames from 'classnames';

import { Props } from './types';
import { useFadeOut } from '../../hooks/fadeOut';
import styles from './styles.module.scss';
import Button from '../Button';

import CloseIcon from '!svg-react-loader?name=CartIcon!../../../assets/icons/close_x.svg';

export const Dialog: React.FC<Props> = (props) => {
  const {
    animationDuration,
    buttons,
    className,
    closeable,
    modifiers,
    showCloseButton,
    children,
    onClose,
    show,
    title,
  } = props;
  const duration = (animationDuration || 0) + 'ms';
  const shouldFadeOut = useFadeOut(show);

  return (
    <div className={styles.container}>
      <aside
        className={classNames(
          modifiers ? modifiers.split(' ').map((m: string) => styles[m]) : null,
          styles.dialog,
          {
            [styles.show]: show,
            [styles.fade_out]: !show && shouldFadeOut,
          },
          className,
        )}
        role="dialog"
        style={{
          transitionDuration: duration,
        }}
        tabIndex={-1}
      >
        <div className={styles.header}>
          <h1 className={styles.title}>{title}</h1>
          {showCloseButton && (
            <Button
              className={styles.close}
              disabled={closeable !== undefined ? !closeable : false}
              modifiers="action"
              onClick={onClose}
              title="Stäng"
            >
              <CloseIcon />
            </Button>
          )}
        </div>

        <div className={styles.body}>
          <div className={styles.inner}>{children}</div>
        </div>

        <div className={styles.footer}>
          {buttons ? (
            buttons.map((button) => button)
          ) : (
            <Button modifiers="primary" onClick={onClose}>
              OK
            </Button>
          )}
        </div>
      </aside>
    </div>
  );
};
