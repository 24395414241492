import React from 'react';
import classNames from 'classnames';

import { Props } from './types';
import styles from './styles.module.scss';
import ProductCard from '../ProductCard';

const ProductsList: React.FC<Props> = (props) => {
  const { className, preload, products, student } = props;

  return (
    <ul className={classNames(styles.row, className)}>
      {products.map((product) => (
        <li className={styles.col} key={product.url}>
          <ProductCard preload={preload} product={product} student={student} />
        </li>
      ))}
    </ul>
  );
};

export default ProductsList;
